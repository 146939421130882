import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent,InputLabel, DialogActions, Button, Grid, LinearProgress, Typography } from '@mui/material';
import Loading from '../Components/Loading'
import Constants from '../Utils/Constants';
import CustomAlert from '../Components/CustomAlert'
import CustomTextField from '../Components/CustomTextField';
import departamentoService from '../Services/DepartamentoService';
import attrationService from '../Services/AttrationService';
import MultiSelect from '../Components/MultiSelect';
import Map from '../Components/Map';
import MultiFileUploader from '../Components/MultiFileUploader';

import Editor from '../Components/Editor/Editor';

function DepartamentoModal({ departamento, coordCiudad, open, onSave, onClose }) {
    const [textFieldValue, setTextFieldValue] = useState({tituloDepto: "", descripcionDepto: "", impCostoDiario: "", impCostoMtto: "", numHuesped:  ""})
    const [locationObject, setLocationObject] = useState({lat: 0, lng: 0})
    const [coordIniciales, setCoordIniciales] = useState({lat: 0, lng: 0})
    const { tituloDepto, descripcionDepto, impCostoDiario, impCostoMtto, numHuesped} = textFieldValue
    const [loading, setLoading] = useState(false)
    const [isEditar, setIsEditar] = useState(false)
    const [numeroPaso, setNumeroPaso] = useState(1)
    const [valorPorcentaje, setValorPorcentaje] = useState(100/3)
    const [maxPasos, setMaxPasos] = useState(5)
    const showLoading = () => setLoading(true)
    const hideLoading = () => setLoading(false)
    const messageType = Constants.MESSAGE_TYPE
    const alertRef = useRef()
    const [serviciosArray, setServiciosArray] = useState([])
    const [atraccionesArray, setAtraccionesArray] = useState([])
    const [archivosArray, setArchivosArray] = useState([])
    const [serviciosSeleccionados, setServiciosSeleccionados] = useState([]);
    const [atraccionesSeleccionados, setAtraccionesSeleccionados] = useState([]);
    const objetoUsuario = JSON.parse(atob(sessionStorage.getItem('objetoUsuario')))
    const [idDepartamento, setIdDepto] = useState('')
    var [arrayServiciosSeleccionados, setArrayServSel] = useState([])
    var [arrayAtraccionesSeleccionados, setArrayAtraccionesSel] = useState([])
    const [isFilesSelected, setIsFilesSelected] = useState(false)
    const [desReglas, setReglas] = useState('')
    const [desSeguridad, setSeguridad] = useState('')
    const [desReglasSel, setReglasSel] = useState('')
    const [desSeguridadSel, setSeguridadSel] = useState('')

    const handleMultiSelectChange = (values) => {
        setServiciosSeleccionados(values);
    };

    const handleAtraccionesMultiSelectChange = (values) => {
        setAtraccionesSeleccionados(values);
    };
    
    const handleTextfieldChange = (e) => {
        const {name, value} = e.target
        setTextFieldValue((prev) => ({
          ...prev,
          [name]: value,
        }))
      }

    const guardarDepartamento = () => {
        if ((!isEditar && archivosArray.length === 0) || isFilesSelected) {
            showAlertMessage("Es necesario subir al menos un archivo!", messageType.WARNING)
            return
        }
        const departamentoRequest = {
            id_departamento: idDepartamento,
            id_usuario: objetoUsuario.idUsuario,
            id_ciudad: 1,
            latitud: locationObject.lat,
            longitud: locationObject.lng,
            des_titulo: tituloDepto,
            des_departamento: descripcionDepto,
            imp_costo_diario: impCostoDiario,
            imp_costo_mtto: impCostoMtto,
            num_huesped: numHuesped,
            arrayServicios: serviciosSeleccionados,
            arrayAtracciones: atraccionesSeleccionados,
            arrayFotos: archivosArray,
            des_seguridad_propiedad: desSeguridadSel,
            des_reglas: desReglasSel
        }
        
        showLoading()
        if (isEditar) {
            departamentoService.actualizarDepartamento(departamentoRequest)
            .then((response) => {
                hideLoading()
                if (response.data.success) {
                    onSave(response.data.message)
                } else {
                    showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                }
            })
            .catch((e) => {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                hideLoading()
            })
        } else {
            departamentoService.agregarDepartamento(departamentoRequest)
            .then((response) => {
                hideLoading()
                if (response.data.success) {
                    onSave(response.data.message)
                } else {
                    showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                }
            })
            .catch((e) => {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                hideLoading()
            })
        }
        
        
    };

    const showAlertMessage = (message, alertType) => {
        if (alertRef.current) {
            alertRef.current.showAlert(message, alertType)
        }
    }

    const obtenerServicios = () => { 
        showLoading()
        departamentoService.consultarServicios()
          .then((response) => {
            hideLoading()
            if (response.data.response.length) {
                setServiciosArray(response.data.response)
            } else {
                setServiciosArray([])
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
    }

    const obtenerAtracciones = () => { 
        showLoading()
        attrationService.getAttrations('null','')
          .then((response) => {
            hideLoading()
            if (response.data.response.length) {
                setAtraccionesArray(response.data.response)
            } else {
                setAtraccionesArray([])
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
    }

    const incrementaPaso = () => {
        var pasoActual = numeroPaso
        switch (pasoActual) {
            case 1:
                if (!descripcionDepto) {
                    showAlertMessage("Descripción es requerido!", messageType.WARNING)
                    return
                }
                if (!serviciosSeleccionados.length > 0) {
                    showAlertMessage("Es necesario selecionar al menos un servicio!", messageType.WARNING)
                    return
                }
                if (!impCostoDiario) {
                    showAlertMessage("Costo diario es requerido!", messageType.WARNING)
                    return
                }
                if (!numHuesped || numHuesped === 0) {
                    showAlertMessage("La cantidad de húespedes es requerido!", messageType.WARNING)
                    return
                }
                break
            case 2:
                if (!desReglasSel || desReglasSel === '<p><br></p>') {
                    showAlertMessage("Es necesario agregar las reglas de la casa!", messageType.WARNING)
                    return
                }
                break
            case 3:
                if (!desSeguridadSel || desSeguridadSel === '<p><br></p>') {
                    showAlertMessage("Es necesario agregar seguridad y propiedad!", messageType.WARNING)
                    return
                }
                break
            case 4:
                if (!locationObject.lat) {
                    showAlertMessage("Es necesario selecionar ubicación!", messageType.WARNING)
                    return
                }
                break
            default:
                break
        }
        pasoActual++
        setValorPorcentaje(100/((maxPasos + 1)-pasoActual))
        setNumeroPaso(pasoActual)
    }

    const disminuyePaso = () => {
        var pasoActual = numeroPaso
        pasoActual--
        setValorPorcentaje(100/(6-pasoActual))
        setNumeroPaso(pasoActual)
    }

    const handleLocationSelect = (location) => {
        setLocationObject(location)
    };

    const handleFilesUpload = (files) => {
        setIsFilesSelected(false)
        setArchivosArray(files)
    }

    useEffect(() => {
        if (open) {
            setNumeroPaso(1)
            setValorPorcentaje(100/((maxPasos + 1)-1))
            setCoordIniciales(coordCiudad)
            if (departamento) {
                setIsEditar(true)     
                setMaxPasos(4)
                setServiciosSeleccionados(departamento.array_servicios)
                setArrayServSel(departamento.array_servicios)
                setAtraccionesSeleccionados(departamento.array_atracciones)
                setArrayAtraccionesSel(departamento.array_atracciones)
                setTextFieldValue({
                    tituloDepto: departamento.des_titulo,
                    descripcionDepto: departamento.des_departamento,
                    impCostoDiario: departamento.imp_costo_diario,
                    impCostoMtto: departamento.imp_costo_mantenimiento,
                    numHuesped: departamento.num_huesped
                })
                setLocationObject({lat: departamento.latitud, lng: departamento.longitud})
                setIdDepto(departamento.id_departamento)
                setReglas(departamento.des_reglas)
                setSeguridad(departamento.des_seguridad_propiedad)
                setReglasSel(departamento.des_reglas)
                setSeguridadSel(departamento.des_seguridad_propiedad)
            } else {
                setMaxPasos(5)
                setIsEditar(false)
                setServiciosSeleccionados([])
                setArrayServSel([])
                setArchivosArray([])
                setAtraccionesSeleccionados([])
                setArrayAtraccionesSel([])
                setTextFieldValue({
                    tituloDepto: '',
                    descripcionDepto: '',
                    impCostoDiario: '',
                    impCostoMtto: '',
                    numHuesped: ''
                })
                setLocationObject({lat: '', lng: ''})
                setIdDepto('')
                setReglas('')
                setSeguridad('')
                setReglasSel('')
                setSeguridadSel('')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, departamento]);

    useEffect(() => {
        obtenerAtracciones()
        obtenerServicios()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditorReglasChange = (values) => {
        setReglasSel(values);
    };

    const handleEditorSeguridadChange = (values) => {
        setSeguridadSel(values);
    };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="true">
      <DialogTitle>{isEditar ? 'Editar departamento' : 'Agregar departamento'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={5}>
                <LinearProgress variant="determinate" value={valorPorcentaje} />
            </Grid>
            <Grid item xs={1}>
                <Typography variant="body2" color="text.secondary">{`${numeroPaso}/${maxPasos}`}</Typography>
            </Grid>
            {numeroPaso === 1 && <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} mt={2} ml={2}>
                    <Typography variant="h5" gutterBottom>
                        Información general
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4} mt={2}>
                    <CustomTextField
                        required
                        name="tituloDepto"
                        label="Titulo"
                        value={tituloDepto}
                        inputProps={{ maxLength: 50 }}
                        regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                        onChange={handleTextfieldChange}
                    />
                </Grid>
                <Grid item xs={6} md={4} mt={2}>
                    <CustomTextField
                        required
                        name="descripcionDepto"
                        label="Descripción"
                        value={descripcionDepto}
                        multiline
                        inputProps={{ maxLength: 1000 }}
                        regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                        onChange={handleTextfieldChange}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <InputLabel>Servicios</InputLabel>
                    <MultiSelect options={serviciosArray} value={arrayServiciosSeleccionados} idKey="id_servicio" labelKey="nom_servicio" onChange={handleMultiSelectChange} />
                </Grid>
                <Grid item xs={6} md={2} mt={2}>
                    <CustomTextField
                        required
                        name="impCostoDiario"
                        label="Costo diario"
                        value={impCostoDiario}
                        inputProps={{ maxLength: 8 }}
                        regex={Constants.NUMERIC_DASH_REGEX}
                        onChange={handleTextfieldChange}
                    />
                </Grid>
                {objetoUsuario.idTipoUsuario === 1 && <Grid item xs={6} md={2} mt={2}>
                    <CustomTextField
                        required
                        name="impCostoMtto"
                        label="Costo Mtto"
                        value={impCostoMtto}
                        inputProps={{ maxLength: 8 }}
                        regex={Constants.NUMERIC_DASH_REGEX}
                        onChange={handleTextfieldChange}
                    />
                </Grid> }
                <Grid item xs={6} md={2} mt={2}>
                    <CustomTextField
                        required
                        name="numHuesped"
                        label="No huéspedes"
                        value={numHuesped}
                        inputProps={{ maxLength: 2 }}
                        regex={Constants.INTEGER_DASH_REGEX}
                        onChange={handleTextfieldChange}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <InputLabel>Atracciones</InputLabel>
                    <MultiSelect options={atraccionesArray} value={arrayAtraccionesSeleccionados} idKey="id_servicio_atraccion" labelKey="nom_atraccion" onChange={handleAtraccionesMultiSelectChange} />
                </Grid>
            </Grid>}
            {numeroPaso === 2 && <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} mt={2} ml={2}>
                    <Typography variant="h5">
                        Reglas de la casa
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Editor titulo="Agregar la descripción de las reglas de la casa:" values={desReglas} onChange={handleEditorReglasChange} />
                </Grid>
            </Grid>}
            {numeroPaso === 3 && <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} mt={2} ml={2}>
                    <Typography variant="h5">
                        Seguridad y propiedad
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Editor titulo="Agregar la descripción de seguridad y propiedad:"  values={desSeguridad} onChange={handleEditorSeguridadChange} />
                
                </Grid>
            </Grid>}
            {numeroPaso === 4 && !isEditar && <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} mt={2} ml={2}>
                    <Typography variant="h5">
                        Ubicación
                    </Typography>
                </Grid>
                <Grid item xs={12} ml={2}>
                    <Typography variant="h6" gutterBottom>
                        Selecciona la ubicación de tu departamento:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Map coordIniciales={coordIniciales} onLocationSelect={handleLocationSelect} />
                </Grid>
            </Grid>}
            {(numeroPaso === 5 || (numeroPaso === 4 && isEditar)) && <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} mt={2} ml={2}>
                    <Typography variant="h5" gutterBottom>
                        Fotografías
                    </Typography>
                    <Grid item xs={12}>
                        <MultiFileUploader onUpload={handleFilesUpload} onSelectedFiles={() => {setIsFilesSelected(true)}} maxFiles={5}/>
                    </Grid>
                </Grid>
            </Grid>}
        </Grid> 
      </DialogContent>
      <DialogActions>
        {
            numeroPaso === 1 ?
            <Button onClick={onClose}>Cancelar</Button> :
            <Button onClick={disminuyePaso}>Regresar</Button>
        }
        {
            maxPasos === numeroPaso ?
            <Button onClick={guardarDepartamento}>Guardar</Button> :
            <Button onClick={incrementaPaso}>Siguiente</Button>
        }
      </DialogActions>
      <Loading open={loading} handleClose={hideLoading} />
      <CustomAlert ref={alertRef} />
    </Dialog>
  );
}

export default DepartamentoModal;
