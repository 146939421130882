import Axios from "../Utils/AxiosModule"

class StateService {
    headers = {
        Authorization: "Bearer " + sessionStorage.getItem("auth")
    }
    async consultarEstado(nomEstado) {
        return await Axios.get(`/estados/${nomEstado}`)
    }
    async agregarEstado(requestEstado) {
        return await Axios.post(`/estados`, requestEstado)
    }
    async actualizarEstado(requestEstado) {
        return await Axios.put(`/estados`, requestEstado)
    }
    async eliminarEstado(idEstado) {
        return await Axios.delete(`/estados/${idEstado}`)
    }
}
const stateService =  new StateService()
export default stateService
