import DepartmentComponent from "../Departments/DepartmenComponent";
import UserComponent from "../User/UserComponent";
import StateComponent from "../States/StateComponent";
import AttrationsComponent from "../Attrations/AttrationsComponent";
import ConfigurationComponent from "../Configuration/ConfigurationComponent";

export const AppRoutes = [
    {
        id: 1,
        ruta: '/user',
        componente: <UserComponent/>,
    },
    {
        id: 2,
        ruta: '/apartment',
        componente: <DepartmentComponent/>,
    },
    {
        id: 3,
        ruta: '/state',
        componente: <StateComponent/>,
    },
    {
        id: 4,
        ruta: '/configuration',
        componente: <ConfigurationComponent/>,
    },
    {
        id: 5,
        ruta: '/attration',
        componente: <AttrationsComponent/>,
    },
]