import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent,InputLabel, DialogActions, Button, Grid, LinearProgress, Typography } from '@mui/material';
import Loading from '../Components/Loading'
import Constants from '../Utils/Constants';
import CustomAlert from '../Components/CustomAlert'
import CustomTextField from '../Components/CustomTextField';
import attrationService from '../Services/AttrationService';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MultiFileUploader from '../Components/MultiFileUploader';

function AttrationModal({ attration, open, onSave, onClose }) {
    const [textFieldValue, setTextFieldValue] = useState({nomAtracion: "", descripcionAtraccion: "", desURL: "", impCosto: 0})
    const { nomAtracion, descripcionAtraccion, desURL, impCosto } = textFieldValue
    const [loading, setLoading] = useState(false)
    const [isEditar, setIsEditar] = useState(false)
    const [numeroPaso, setNumeroPaso] = useState(1)
    const [valorPorcentaje, setValorPorcentaje] = useState(100/2)
    const [maxPasos, setMaxPasos] = useState(2)
    const showLoading = () => setLoading(true)
    const hideLoading = () => setLoading(false)
    const messageType = Constants.MESSAGE_TYPE
    const alertRef = useRef()
    const [idGiro, setIdGiro] = useState(1)
    const [girosArray, setGirosArray] = useState([])
    const [archivosArray, setArchivosArray] = useState([])
    const [idAtracion, setIdAtraccion] = useState('')
    const [isFilesSelected, setIsFilesSelected] = useState(false)

    const handleTextfieldChange = (e) => {
        const {name, value} = e.target
        setTextFieldValue((prev) => ({
          ...prev,
          [name]: value,
        }))
      }

    const guardarAttration = () => {
        if ((!isEditar && archivosArray.length === 0) || isFilesSelected) {
            showAlertMessage("Es necesario subir al menos un archivo!", messageType.WARNING)
            return
        }
        const attrationRequest = {
            id_servicio_atraccion: idAtracion,
            id_giro: idGiro,
            nom_atraccion: nomAtracion,
            des_atraccion: descripcionAtraccion,
            url_atraccion: desURL,
            imp_costo: parseFloat(impCosto),
            arrayFotos: archivosArray
        }
        showLoading()
        if (isEditar) {
            attrationService.updateAttration(attrationRequest)
            .then((response) => {
                hideLoading()
                if (response.data.success) {
                    onSave(response.data.message)
                } else {
                    showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                }
            })
            .catch((e) => {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                hideLoading()
            })
        } else {
            attrationService.addAttration(attrationRequest)
            .then((response) => {
                hideLoading()
                if (response.data.success) {
                    onSave(response.data.message)
                } else {
                    showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                }
            })
            .catch((e) => {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                hideLoading()
            })
        }
        
        
    };

    const showAlertMessage = (message, alertType) => {
        if (alertRef.current) {
            alertRef.current.showAlert(message, alertType)
        }
    }

    const obtenerGiros = () => { 
        showLoading()
        attrationService.getGiros()
          .then((response) => {
            hideLoading()
            if (response.data.response.length) {
                setGirosArray(response.data.response)
            } else {
                setGirosArray([])
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
    }

    const incrementaPaso = () => {
        var pasoActual = numeroPaso
        switch (pasoActual) {
            case 1:
                if (!nomAtracion) {
                    showAlertMessage("Nombre de atracción es requerido!", messageType.WARNING)
                    return
                }
                if (!descripcionAtraccion) {
                    showAlertMessage("Descripción es requerido!", messageType.WARNING)
                    return
                }
                if (!idGiro) {
                    showAlertMessage("Giro es requerido!", messageType.WARNING)
                    return
                }
                if (!impCosto) {
                    showAlertMessage("Costo es requerido!", messageType.WARNING)
                    return
                }
                break
            default:
                break
        }
        pasoActual++
        setValorPorcentaje(100/((maxPasos + 1)-pasoActual))
        setNumeroPaso(pasoActual)
    }

    const disminuyePaso = () => {
        var pasoActual = numeroPaso
        pasoActual--
        setValorPorcentaje(100/(4-pasoActual))
        setNumeroPaso(pasoActual)
    }

    const handleSelectChange = (event) => {
        setIdGiro(event.target.value);
      };

    const handleFilesUpload = (files) => {
        setIsFilesSelected(false)
        setArchivosArray(files)
    }

    useEffect(() => {
        if (open) {
            setNumeroPaso(1)
            setValorPorcentaje(100/((maxPasos + 1)-1))
            if (attration) {
                setIsEditar(true)     
                setMaxPasos(2)
                setIdGiro(attration.id_giro)
                setTextFieldValue({
                    nomAtracion: attration.nom_atraccion,
                    descripcionAtraccion: attration.des_atraccion,
                    desURL: attration.url_atraccion,
                    impCosto: attration.imp_costo
                })
                setIdAtraccion(attration.id_servicio_atraccion)
            } else {
                setMaxPasos(2)
                setIsEditar(false)
                setArchivosArray([])
                setTextFieldValue({
                    nomAtracion: "",
                    descripcionAtraccion: "",
                    desURL: "",
                    impCosto: 0
                })
                setIdAtraccion('')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, attration]);

    useEffect(() => {
        obtenerGiros()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="true">
      <DialogTitle>{isEditar ? 'Editar atracción' : 'Agregar atracción'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={5}>
                <LinearProgress variant="determinate" value={valorPorcentaje} />
            </Grid>
            <Grid item xs={1}>
                <Typography variant="body2" color="text.secondary">{`${numeroPaso}/${maxPasos}`}</Typography>
            </Grid>
            {numeroPaso === 1 && <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} mt={2} ml={2}>
                    <Typography variant="h5" gutterBottom>
                        Información general
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4} mt={2}>
                    <CustomTextField
                        required
                        name="nomAtracion"
                        label="Nombre"
                        value={nomAtracion}
                        inputProps={{ maxLength: 50 }}
                        regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                        onChange={handleTextfieldChange}
                    />
                </Grid>
                <Grid item xs={6} md={4} mt={2}>
                    <CustomTextField
                        required
                        name="descripcionAtraccion"
                        label="Descripción"
                        value={descripcionAtraccion}
                        multiline
                        inputProps={{ maxLength: 500 }}
                        regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                        onChange={handleTextfieldChange}
                    />
                </Grid>
                <Grid item xs={6} md={4} mt={2}>
                    <CustomTextField
                        required
                        name="desURL"
                        label="URL"
                        value={desURL}
                        inputProps={{ maxLength: 255 }}
                        regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                        onChange={handleTextfieldChange}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <InputLabel>Giros</InputLabel>
                    <Select
                        fullWidth
                        value={idGiro}
                        onChange={handleSelectChange}
                        label="Giros"
                        >
                        {girosArray.map((girosArray) => (
                            <MenuItem key={girosArray.id_giro} value={girosArray.id_giro}>{girosArray.nom_giro}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={6} md={2} mt={2}>
                    <CustomTextField
                        required
                        name="impCosto"
                        label="Precio"
                        value={impCosto}
                        inputProps={{ maxLength: 16 }}
                        regex={Constants.NUMERIC_DASH_REGEX}
                        onChange={handleTextfieldChange}
                    />
                </Grid>
            </Grid>}
            {(numeroPaso === 2 || (numeroPaso === 2 && isEditar)) && <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} mt={2} ml={2}>
                    <Typography variant="h5" gutterBottom>
                        Fotografías
                    </Typography>
                    <Grid item xs={12}>
                        <MultiFileUploader onUpload={handleFilesUpload} onSelectedFiles={() => {setIsFilesSelected(true)}} maxFiles={5}/>
                    </Grid>
                </Grid>
            </Grid>}
        </Grid> 
      </DialogContent>
      <DialogActions>
        {
            numeroPaso === 1 ?
            <Button onClick={onClose}>Cancelar</Button> :
            <Button onClick={disminuyePaso}>Regresar</Button>
        }
        {
            maxPasos === numeroPaso ?
            <Button onClick={guardarAttration}>Guardar</Button> :
            <Button onClick={incrementaPaso}>Siguiente</Button>
        }
      </DialogActions>
      <Loading open={loading} handleClose={hideLoading} />
      <CustomAlert ref={alertRef} />
    </Dialog>
  );
}

export default AttrationModal;
