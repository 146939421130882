import { Skeleton } from '@mui/material';

function CustomSkelleton() {
  return (
    <div style={{ 
      position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
     }}>
      <Skeleton variant="rectangular" width='90%' height={300} style={{ marginBottom: '20px' }} />
      <Skeleton variant="text" width='90%' />
      <Skeleton variant="text" width='90%' />
      <Skeleton variant="text" width='90%' />
    </div>
  );
}

export default CustomSkelleton