import React, {useState, forwardRef, useImperativeHandle} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const CustomConfirmDialog = forwardRef((props, ref) => {
    const [confirmDialogData, setConfirmData] = useState({
        titulo: '',
        descripcion: '',
        textoCancelar: '',
        textoAceptar: '',
        onClose: null,
        onAgree: null
    });
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        if (confirmDialogData.onClose) {
            confirmDialogData.onClose();
        }
        setOpen(false)
    };

    const handleAgree = () => {
        if (confirmDialogData.onAgree) {
            confirmDialogData.onAgree();
        }
        setOpen(false)
    };

    // Function to show the alert with a specific message
    useImperativeHandle(ref, () => ({
        showConfirm(confirmData) {
            setConfirmData({
                titulo: confirmData.titulo,
                descripcion: confirmData.descripcion,
                textoCancelar: confirmData.textoCancelar,
                textoAceptar: confirmData.textoAceptar,
                onClose: confirmData.onClose,
                onAgree: confirmData.onAgree
            });
            setOpen(true)
        }
    }))

    return (
        <React.Fragment>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {confirmDialogData.titulo}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {confirmDialogData.descripcion}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            {
                confirmDialogData.textoCancelar &&
                <Button onClick={handleClose}>{confirmDialogData.textoCancelar}</Button>
            }
            {
                confirmDialogData.textoAceptar &&
                <Button onClick={handleAgree} autoFocus>
                    {confirmDialogData.textoAceptar}
                </Button>
            }
            </DialogActions>
        </Dialog>
        </React.Fragment>
    )
})

export default CustomConfirmDialog