
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import * as React from 'react'
import Grid from '@mui/material/Grid';
import LoginComponent from './Login/LoginComponent'
import HomeComponent from './Home/HomeComponent'
import ReservationComponent from './Reservations/ReservationComponent'
import PrivateRoutes from './Utils/PrivateRoutes'
import PublicRoutes from './Utils/PublicRoutes'
import { AppRoutes } from './Utils/AppRoutes'
import {
  Routes,
  Route,
  BrowserRouter,
  Outlet
} from "react-router-dom";
import ReservationDetail from './Reservations/ReservationDetail'
import PrivacyPolicy from './Components/PrivacyPolicy'
import TermsAndConditions from './Components/TermsAndConditions'

function App() {
  return ( 
    <div className="App">
      <Grid container>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<ReservationComponent /> } />
            <Route element={(
                <>
                <PrivateRoutes />
                <Outlet />
                </>
                )}>
                  <Route element={<HomeComponent/>} path='/home'/>
                  {AppRoutes.map(({ id, ruta, componente }) => (
                    <Route key={id} path={ruta} element={componente} />
                  ))}
            </Route>
            <Route element={<PublicRoutes />}>
              <Route element={<LoginComponent />} path='/login' />
              <Route element={<ReservationComponent />} path='/reserva' />
              <Route element={<ReservationDetail />} path='/reservaDetalle' />
              <Route element={<PrivacyPolicy />} path='/privacidad' />
              <Route element={<TermsAndConditions />} path='/terminos' />
            </Route>
          </Routes>
        </BrowserRouter>
      </Grid>
    </div>
    
  )
}

export default App
