import React, { useState, useEffect, useRef } from 'react';
import Loading from '../Components/Loading';
import { Grid, Button, Table, Typography, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CustomAlert from '../Components/CustomAlert'
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import departamentoService from '../Services/DepartamentoService';
import Constants from '../Utils/Constants';
import CustomConfirmDialog from '../Components/CustomConfirmDialog'
import userService from '../Services/UserService';
import DepartamentoModal from './DepartmentModal';

function DepartmentComponent() {
    const messageType = Constants.MESSAGE_TYPE
    const [idCiudad, setIdCiudad] = useState('');
    const [ciudadesArray, setCiudadesArray] = useState([])
    const [idUsuario, setIdUsuario] = useState('');
    const [usuariosArray, setUsuariosArray] = useState([])
    const [departamentosArray, setDepartamentosArray] = useState([]);
    const [loading, setLoading] = useState(false)
    const showLoading = () => setLoading(true)
    const hideLoading = () => setLoading(false)
    const alertRef = useRef()
    const confirmRef = useRef()
    const [deptoId, setDeptoId] = useState("") 
    const [deptoObject, setDeptoObject] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmed, setConfirmed] = useState(false);
    const [coordSelected, setCoordSelected] = useState({lat: 0, lng: 0});
    const objetoUsuario = JSON.parse(atob(sessionStorage.getItem('objetoUsuario')))

    const obtenerCiudades = () => {
        showLoading()
        departamentoService.consultarCiudades()
          .then((response) => {
            if (response.data.response.length) {
                let resp = response.data.response
                setCiudadesArray(resp)
                setIdCiudad(resp[0].id_ciudad)
                setCoordSelected({lat: resp[0].latitud, lng: resp[0].longitud})
                obtenerUsuarios()
            } else {
                setCiudadesArray([])
                hideLoading()
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
      }

    const obtenerUsuarios = () =>  {
        userService.consultarUsuarios('')
          .then((response) => {
            hideLoading()
            if (response.data.response.length) {
                setUsuariosArray(response.data.response)
                if (objetoUsuario.idTipoUsuario === 1) {
                    setIdUsuario(response.data.response[0].id_usuario)
                }
            } else {
                setUsuariosArray([])
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
    }

    const showAlertMessage = (message, alertType) => {
        if (alertRef.current) {
            alertRef.current.showAlert(message, alertType)
        }
    }

    const handleOpenConfirm = async (deptoObject) => {
        const confirmData = {
            titulo: "Eliminar departamento",
            descripcion: `¿Desea eliminar el departamento?`,
            textoCancelar: Constants.CANCEL_TEXT,
            textoAceptar: Constants.ACCEPT_TEXT,
            onAgree: handleAgreeConfirm
        }
        if (confirmRef.current) {
            confirmRef.current.showConfirm(confirmData)
        }
        const deptoId = deptoObject.id_departamento;
        setDeptoId(deptoId);
    };

    const handleAgreeConfirm = () => {
        setConfirmed(true);
    };

    const handleDeleteDepartamento = () => {
        showLoading()
        departamentoService.eliminarDepartamento(deptoId)
          .then((response) => {
            hideLoading()
            if (response.data.success) {
                searchDepartments()
                showAlertMessage("Departamento eliminado correctamente", messageType.SUCCESS)
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
            }
            setDeptoId(null);
            setConfirmed(false);
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
            setDeptoId(null);
            setConfirmed(false);
          })
    };

    const searchDepartments = () => {
        showLoading()
        departamentoService.consultarDepartamento(idCiudad, idUsuario)
          .then((response) => {
            hideLoading()
            if (response.data.response.length) {
                setDepartamentosArray(response.data.response)
            } else {
                setDepartamentosArray([])
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
      }

    const handleSelectCity = (event) => {
        let idCiudad = event.target.value
        let objCiudad = ciudadesArray.find(ciudad => ciudad.id_ciudad === idCiudad);
        setIdCiudad(idCiudad);
        setCoordSelected({lat: objCiudad.latitud, lng: objCiudad.longitud})
    };

    const handleSelectUser = (event) => {
        setIdUsuario(event.target.value);
    };

    const handleOpenModal = (departamento = null) => {
        setDeptoObject(departamento);
        setIsModalOpen(true);
    };

    const handleGuardarDepto = (message) => {
        searchDepartments()
        setIsModalOpen(false);
        showAlertMessage(message, messageType.SUCCESS)
    }

    useEffect(() => {
        obtenerCiudades()
        if (objetoUsuario.idTipoUsuario !== 1) {
            setIdUsuario(objetoUsuario.idUsuario)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (deptoId != null && isConfirmed) {
            handleDeleteDepartamento()
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [deptoId,isConfirmed]);
    return (
        <Grid container alignItems="center" item spacing={2} xs mt={10} ml={5} mr={2}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Catálogo de departamentos
                </Typography>
            </Grid>
            <Grid item xs={5} md={4}>
                <InputLabel>Ciudad</InputLabel>
                <Select
                    fullWidth
                    value={idCiudad}
                    onChange={handleSelectCity}
                    label="Ciudad"
                    >
                    {ciudadesArray.map((ciudad) => (
                        <MenuItem key={ciudad.id_ciudad} value={ciudad.id_ciudad}>{ciudad.nom_ciudad}</MenuItem>
                    ))}
                </Select>
            </Grid>
            {objetoUsuario.idTipoUsuario === 1 && 
            <Grid item xs={5} md={4}>
                <InputLabel>Arrendador</InputLabel>
                <Select
                    fullWidth
                    value={idUsuario}
                    onChange={handleSelectUser}
                    label="Arrendador"
                    >
                    {usuariosArray.map((usuario) => (
                        <MenuItem key={usuario.id_usuario} value={usuario.id_usuario}>{usuario.nom_arrendador}</MenuItem>
                    ))}
                </Select>
                
            </Grid> }
            <Grid item xs={1} md={2}>
                <Button startIcon={<Search />} title='Buscar Departamento' onClick={() => searchDepartments()}/>
                <Button startIcon={<AddIcon />} title='Agregar Departamento' onClick={() => handleOpenModal(null)} />
            </Grid>
            
            {/*Equivalente al show/hide segun la condicion se muestra el elemento*/
                departamentosArray.length > 0 &&
                <Grid item xs={12} mt={2}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Arrendador</TableCell>
                                <TableCell>Titulo</TableCell>
                                <TableCell>Descripción depto</TableCell>
                                <TableCell>Costo diario</TableCell>
                                {objetoUsuario.idTipoUsuario === 1 &&  <TableCell>Costo de mantenimiento</TableCell> }
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {departamentosArray.map((departamento) => (
                            <TableRow key={departamento.id_departamento}>
                                <TableCell>{departamento.nom_arrendador}</TableCell>
                                <TableCell>{departamento.des_titulo}</TableCell>
                                <TableCell>{departamento.des_departamento}</TableCell>
                                <TableCell>{departamento.imp_costo_diario}</TableCell>
                                {objetoUsuario.idTipoUsuario === 1 && <TableCell>{departamento.imp_costo_mantenimiento}</TableCell> }
                                <TableCell>
                                <Tooltip title="Editar departamento">
                                    <Button color='success' onClick={() => handleOpenModal(departamento)}><EditIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Eliminar departamento">
                                    <Button color='error' onClick={() => handleOpenConfirm(departamento)}><DeleteIcon /></Button>
                                </Tooltip>                            
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
            <DepartamentoModal departamento={deptoObject} coordCiudad={coordSelected} open={isModalOpen} onSave={handleGuardarDepto} onClose={() => setIsModalOpen(false)} />
            <Loading open={loading} handleClose={hideLoading} />
            <CustomAlert ref={alertRef} />
            <CustomConfirmDialog ref={confirmRef}/>
        </Grid>
    )
}
export default DepartmentComponent