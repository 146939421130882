const Constants = {
    ALPHA_NUMERIC_DASH_REGEX : /^[.?a-zA-Z0-9ñÑ -]+$/,
    EMAIL_REGEX:  /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    NUMERIC_DASH_REGEX : /^\d*\.?\d*$/,
    INTEGER_DASH_REGEX : /^\d*?\d*$/,
    MESSAGE_TYPE : {
        SUCCESS: "success",
        INFO: "info",
        WARNING: "warning",
        ERROR: "error"
    },
    GENERIC_MESSAGE: "Ocurrió un error, intenta de nuevo por favor",
    NO_INFO_MESSAGE: "No se encontro información, intenta de nuevo",
    LOGOUT: "Cerrar sesión",
    APP_COLOR: "#30D5DB",
    GRAY_COLOR: "#ECECEC",
    CANCEL_TEXT: "Cancelar",
    ACCEPT_TEXT: "Aceptar"
}

export default Constants