import React, { useState, useEffect, useRef } from 'react';
import Loading from '../Components/Loading';
import { Grid} from '@mui/material';
import CustomAlert from '../Components/CustomAlert'
import generalService from '../Services/GeneralService';
import Constants from '../Utils/Constants';
import CustomConfirmDialog from '../Components/CustomConfirmDialog'
import Editor from '../Components/Editor/Editor';

function TermsAndConditionsComponent() {
    const messageType = Constants.MESSAGE_TYPE
    const [loading, setLoading] = useState(false)
    const showLoading = () => setLoading(true)
    const hideLoading = () => setLoading(false)
    const alertRef = useRef()
    const confirmRef = useRef()
    const [generalId, setGeneralId] = useState("") 

    const [desPoliticas, setPoliticas] = useState('')
    const [desPoliticasSel, setPoliticasSel] = useState('')

    const obtenerGenerales = () => {
        showLoading()
        generalService.getGeneral()
          .then((response) => {
            hideLoading()
            if (response.data.response.length) {
                setPoliticas(response.data.response[0].des_terminos_condiciones	)
                setPoliticasSel(response.data.response[0].des_terminos_condiciones	)
                setGeneralId(response.data.response[0].id_config)
            } else {
                setPoliticas('')
                setPoliticasSel('')
                setGeneralId('')
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
      }

    const showAlertMessage = (message, alertType) => {
        if (alertRef.current) {
            alertRef.current.showAlert(message, alertType)
        }
    }
    
    const postGeneral = () => {
        if (!desPoliticasSel) {
            showAlertMessage("Es necesario agregar información!", messageType.WARNING)
            return
        }
        const generalepartamento = {
            id_config: generalId,
            des_terminos_condiciones: desPoliticasSel
        }
        
        showLoading()
        generalService.updateGeneral(generalepartamento)
            .then((response) => {
                hideLoading()
                if (response.data.success) {
                    showAlertMessage(response.data.message, messageType.SUCCESS)
                } else {
                    showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                }
            })
            .catch((e) => {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
                hideLoading()
            })
       
    };

    useEffect(() => {
        obtenerGenerales()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (values) => {
        setPoliticasSel(values);
    };

    
    return (
        <Grid container alignItems="center" item spacing={2} xs mt={1}>           
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Editor titulo="Agregar la descripción de terminos y condiciones:"  values={desPoliticas} onChange={handleChange} onClick={postGeneral} />
                </Grid>
            </Grid>
            <Loading open={loading} handleClose={hideLoading} />
            <CustomAlert ref={alertRef} />
            <CustomConfirmDialog ref={confirmRef}/>
        </Grid>
    )
}
export default TermsAndConditionsComponent