// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__VYXw7 {
    padding: 2rem 3rem;
  }
  
  .styles_label__wxRFE {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .styles_editor__M7j1Y {
    height: 500px;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
  
  .styles_btn__RdXIy {
    border: none;
    color: #fff;
    border-radius: 20px;
    padding: 0.6rem 2rem;
    background-color: royalblue;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Editor/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,oBAAoB;IACpB,2BAA2B;EAC7B","sourcesContent":[".wrapper {\n    padding: 2rem 3rem;\n  }\n  \n  .label {\n    font-size: 1rem;\n    font-weight: 500;\n  }\n  \n  .editor {\n    height: 500px;\n    margin-top: 1rem;\n    margin-bottom: 4rem;\n  }\n  \n  .btn {\n    border: none;\n    color: #fff;\n    border-radius: 20px;\n    padding: 0.6rem 2rem;\n    background-color: royalblue;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__VYXw7`,
	"label": `styles_label__wxRFE`,
	"editor": `styles_editor__M7j1Y`,
	"btn": `styles_btn__RdXIy`
};
export default ___CSS_LOADER_EXPORT___;
