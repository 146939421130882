import React, { useState } from 'react';
import { Button, CircularProgress, Grid, Typography, Alert, Card, CardMedia } from '@mui/material';

const MultiFileUploader = ({ onUpload, onSelectedFiles, maxFiles }) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [showMaxFilesAlert, setShowMaxFilesAlert] = useState(false);
  const [showFileSizeAlert, setShowFileSizeAlert] = useState(false); // New state for file size alert

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Check file size for each selected file
    const oversizedFiles = selectedFiles.filter(file => file.size > 500 * 1024); // 500 KB in bytes

    if (selectedFiles.length > maxFiles) {
      setShowMaxFilesAlert(true);
      setFiles([]);
      return;
    } else if (oversizedFiles.length > 0) {
      setShowFileSizeAlert(true);
      setFiles([]);
      return;
    }

    setShowMaxFilesAlert(false);
    setShowFileSizeAlert(false);
    setFiles(selectedFiles);
    onSelectedFiles(true);
  };

  const handleUpload = () => {
    setUploading(true);

    // Convert files to Base64
    const promises = files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result.split(',')[1]; // Extract base64 string without prefix
          resolve({ name: file.name, data: base64String });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((base64Files) => {
        onUpload(base64Files); // Invoking the callback function with Base64 files
        setIsUploaded(true);
        setFiles([]);
        setUploading(false);
      })
      .catch((error) => {
        setUploading(false);
      });
  };

  return (
    <Grid container spacing={2}>
        <Grid item xs={3} mt={2} mb={2} mr={-10}>
            <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
                disabled={isUploaded}
                style={{ display: 'none' }}
                id="file-upload"
            />
            <label htmlFor="file-upload">
                <Button variant="contained" component="span" disabled={isUploaded}>
                    Seleccionar archivos
                </Button>
            </label>
        </Grid>
        <Grid item xs={3} mt={2}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                disabled={files.length === 0 || uploading}
            >
                {uploading ? <CircularProgress size={24} /> : 'Subir archivos'}
            </Button>
        </Grid>
        <Grid container spacing={2}>
            {files.map((file, index) => (
                <Grid item key={index}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                        component="img"
                        height="140"
                        image={URL.createObjectURL(file)}
                        alt={file.name}
                        />
                    </Card>
                    <Typography>{file.name}</Typography>
                </Grid>
            ))}
        </Grid>
        {showMaxFilesAlert && (
            <Grid item xs={12} mt={2}>
                <Alert severity="error" onClose={() => setShowMaxFilesAlert(false)}>
                    Solo puedes subir {maxFiles} archivos!
                </Alert>
            </Grid>
        )}
        {showFileSizeAlert && (
            <Grid item xs={12} mt={2}>
                <Alert severity="error" onClose={() => setShowFileSizeAlert(false)}>
                    Solo puedes subir archivos con un tamaño máximo de 500KB.
                </Alert>
            </Grid>
        )}
        {isUploaded && (
            <Grid item xs={12} mt={2}>
                <Alert severity="success" onClose={() => setIsUploaded(false)}>
                    Archivos agregados correctamente!
                </Alert>
            </Grid>
        )}
    </Grid>
  );
};

export default MultiFileUploader;
