import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Divider, CardContent, CardActionArea, CardMedia, Paper, Button, Card} from '@mui/material';
import Loading from '../Components/Loading'
import reservationService from '../Services/ReservationService';
import Constants from '../Utils/Constants';
import CustomAlert from '../Components/CustomAlert'
import CustomDatePicker from '../Components/DatePicker';
import dayjs from 'dayjs';
import departamentoService from '../Services/DepartamentoService';
import CustomSkelleton from '../Components/CustomSkelleton';
import CustomTextField from '../Components/CustomTextField';
import { Search } from '@mui/icons-material';
import Footer from '../Components/Footer';
export default function ReservationComponent() {
    const [loading, setLoading] = useState(false);
    const [skelletonLoading, setSkelletonLoading] = useState(false);
    const [arrayDepartamentos, setArrayDeptos] = useState([]);
    const alertRef = useRef();
    const navigate = useNavigate();
    const messageType = Constants.MESSAGE_TYPE;
    const [fecInicio, setFecInicio] = useState(null);
    const [fecFin, setFecFin] = useState(null);
    const [nomCiudad, setNomCiudad] = useState('')
    const [textFieldValue, setTextFieldValue] = useState({numHuespedes: ""})
    const { numHuespedes } = textFieldValue

    const obtenerCiudades = async () => {
        setLoading(true);
        try {
            const response = await departamentoService.consultarCiudades();
            setLoading(false);
            if (response.data.response.length) {
                let objResp = response.data.response[0]
                setNomCiudad(objResp.nom_ciudad)
                getDepartamentos()
            } else {
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
        } catch (error) {
            setLoading(false)
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        }
    }
    const getDepartamentos = async () => {
        setSkelletonLoading(true);
        try {
            let numHuesped = numHuespedes === '' ? null : numHuespedes
            let fec_inicio = fecInicio ? dayjs(fecInicio).format('YYYY-MM-DD') : null
            let fec_fin = fecFin ? dayjs(fecFin).format('YYYY-MM-DD') : null
            const response = await reservationService.consultarDepartamentos(numHuesped, fec_inicio, fec_fin);
            setSkelletonLoading(false);
            if (response.data.response.length) {
                setArrayDeptos(response.data.response)
            } else {
                setArrayDeptos([])
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
        } catch (error) {
            setSkelletonLoading(false);
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        }
        
    }

    const handleTextfieldChange = (e) => {
        const {name, value} = e.target
        setTextFieldValue((prev) => ({
          ...prev,
          [name]: value,
        }))
    }
    useEffect(() => {
        obtenerCiudades();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFechaInicioCambio = (date) => {
        setFecInicio(date);
    };

    const onFechaFinCambio = (date) => {
        setFecFin(date)
    }

    const onDepartamentSelected = (departamento) => () => {
        departamento.coordIniciales = {lat: departamento.latitud, lng: departamento.longitud}
        navigate('/reservaDetalle', {state: departamento})
    }

    const showAlertMessage = (message, alertType) => {
        if (alertRef.current) {
            alertRef.current.showAlert(message, alertType)
        }
    }
    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={4} md={2} alignSelf='center' ml={2}>
                <img
                    src='../../Assets/stayin_logo_combined.png'
                    sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                    style={{
                        width: 'auto',
                        height: 75,
                        display: 'block',
                    }}
                    alt="StayIn Logo"
                />
            </Grid>
            <Grid item xs={12} md={9} ml={2}>
                <Grid container>
                    <Grid item xs={12}>
                        <Paper elevation={5} square={false} sx={{ borderRadius: '30px' }}>
                            <Grid container>
                                <Grid item xs={2} md={1} p={2} ml={2}>
                                    <Typography variant="h6" align='left' color="text.secondary">
                                        Destino
                                    </Typography>
                                    <Typography variant="h6" align='left' color="text.primary">
                                        {nomCiudad}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Divider orientation='vertical'/>
                                </Grid>
                                <Grid item xs={3} p={2}>
                                    <CustomDatePicker
                                        label="Fecha entrada"
                                        initialValue={fecInicio}
                                        minDate={dayjs()}
                                        slots={{
                                            openPickerButton: () => null            
                                        }}
                                        onChange={onFechaInicioCambio}/>
                                </Grid>
                                <Grid item xs={3} p={2}>
                                    <CustomDatePicker
                                        label="Fecha Salida"
                                        initialValue={fecFin}
                                        minDate={fecInicio}
                                        slots={{
                                            openPickerButton: () => null            
                                        }}
                                        onChange={onFechaFinCambio}/>
                                </Grid>
                                <Grid item xs={2} p={1}>
                                    <CustomTextField
                                        name="numHuespedes"
                                        label="Huespedes"
                                        value={numHuespedes}
                                        inputProps={{ maxLength: 2 }}
                                        regex={Constants.INTEGER_DASH_REGEX}
                                        onChange={handleTextfieldChange}
                                    />
                                </Grid>
                                <Grid item xs={1} alignSelf='center' ml={5}>
                                    <Button startIcon={<Search />} style={{backgroundColor: Constants.APP_COLOR, borderRadius: '16px'}} variant='contained' title='Buscar Departamentos' onClick={() => getDepartamentos()}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {skelletonLoading ? (
                <CustomSkelleton />
                ) : (
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                    {arrayDepartamentos.map((depto, index) => (
                        <Grid item md={4} xs={6} key={depto.id_departamento} >
                            <Card variant="elevation" elevation={10} square={false} style={{
                                borderRadius: '12px',
                                height: '100%',
                                backgroundColor: Constants.GRAY_COLOR
                            }}  onClick={onDepartamentSelected(depto)}>
                                <CardActionArea>
                                    <CardMedia
                                        sx={{ height: 300 }}
                                        image={`data:image;base64,${depto.array_fotos[0].imageBase64}`}
                                    />
                                    <CardContent align='center'>
                                        <Typography variant="h6" align='left' color="text.secondary">
                                            {depto.des_titulo}
                                        </Typography>
                                        <Typography variant="subtitle2" align='left' color="text.secondary">
                                            {`$${Math.floor(depto.imp_costo_diario).toLocaleString()} Noche`}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                    </Grid>
                    
                </Grid>
            )}
            <Loading open={loading} handleClose={() => setLoading(false)} />
            <CustomAlert ref={alertRef} />
            <Grid item xs={12} mt={8}>
                <Footer/>
            </Grid>
            
        </Grid>
        </>
    );
}
