// Footer.js

import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/system';
import Constants from '../Utils/Constants';
import {Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FooterContainer = styled('footer')({
    backgroundColor: Constants.GRAY_COLOR,
    marginTop: 'calc(10% + 60px)',
    width: '100%',
    position: 'fixed',
    bottom: 0,
});

const SocialIconButton = styled(IconButton)({
  marginRight: '8px', // Adjust spacing between icons as needed
});



const Footer = () => {
    const navigate = useNavigate();
    return (
        <FooterContainer>
        <Grid container spacing={2} justifyContent="center" mt={1}>
            <Grid item xs={3} alignSelf='center'>
            <Typography variant="body1" color='text.secondary' align='center'>
                © 2024 Stayin.
            </Typography>
            </Grid>
            <Grid item xs={2} alignSelf='center'>
                <Button size='large' variant='text' style={{textTransform: 'none'}} onClick={() => navigate('/privacidad')}>
                    <Typography variant="body1" color='text.secondary' align='center'>
                        Politica de privacidad
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={2} alignSelf='center'>
                <Button size='large' variant='text' style={{textTransform: 'none'}} onClick={() => navigate('/terminos')}>
                    <Typography variant="body1" color='text.secondary' align='center'>
                        Terminos y condiciones
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={5}>
            <SocialIconButton aria-label="Facebook">
                <FacebookIcon />
            </SocialIconButton>
            <SocialIconButton aria-label="Twitter">
                <TwitterIcon />
            </SocialIconButton>
            <SocialIconButton aria-label="Instagram">
                <InstagramIcon />
            </SocialIconButton>
            </Grid>
        </Grid>
        </FooterContainer>
    );
};

export default Footer;
