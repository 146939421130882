import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const Map = ({ onLocationSelect, draggable, markerCoord, coordIniciales }) => {
  const [markerLocation, setMarkerLocation] = useState({lat: 0, lng: 0});
  const [startLocation, setStartLocation] = useState({lat: 0, lng: 0});

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_MAP_KEY
  });

  const OPTIONS = {
    minZoom: 6,
    maxZoom: 15,
    draggable: draggable
  }
  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  useEffect(() => {
    if (coordIniciales) {
      setStartLocation({lat: coordIniciales.lat, lng: coordIniciales.lng})
    }
    if (markerCoord) {
      setMarkerLocation({lat: markerCoord.lat, lng: markerCoord.lng})
    }
  }, [markerCoord, coordIniciales]);

  const handleMapClick = (event) => {
    setMarkerLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    });
    
  };

  const markerChanged = () => {
    if (markerLocation) {
        onLocationSelect(markerLocation);
    }
  };

  const onLoadMap = (map) => {
    if (startLocation.lat !== 0) {
        const bounds = new window.google.maps.LatLngBounds(startLocation)
        map.fitBounds(bounds)
    }    
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={startLocation}
          options={OPTIONS}
          onLoad={onLoadMap}
          zoom={13}
          onClick={handleMapClick}
        >
          <Marker onPositionChanged={markerChanged} position={{ lat: markerLocation.lat, lng: markerLocation.lng }}/>
        </GoogleMap>
      )}
    </div>
  );
};

export default Map;
