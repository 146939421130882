import React, { useState } from 'react';
import { Grid, Typography, InputLabel, Select, MenuItem  } from '@mui/material';
import PaymentComponent from '../PaymentsMethods/PaymentComponent';
import CancelationPolicyComponent from '../PolicyComponents/CancelationPolicyComponent';
import PrivacyPolicyComponent from '../PolicyComponents/PrivacyPolicyComponent';
import TermsAndConditionsComponent from '../PolicyComponents/TermsAndConditionsComponent';

function ConfigurationComponent() {
    const [idConfiguracion, setIdConfiguracion] = useState('');
    const [titleConfiguracion, setTitleConfiguracion] = useState('');
    const configuracionesArray =[
        {id_configuracion: 0, nom_configuracion: "Tipo de pago"},
        {id_configuracion: 1, nom_configuracion: "Política de cancelación"},
        {id_configuracion: 2, nom_configuracion: "Política de privacidad"},
        {id_configuracion: 3, nom_configuracion: "Terminos y condiciones"}
    ]

    const onSelectConfig = (event) => {
        let idConfiguracion = event.target.value
        setIdConfiguracion(idConfiguracion);
        let configSeleccionada = configuracionesArray.find(config => config.id_configuracion === idConfiguracion)
        setTitleConfiguracion(configSeleccionada.nom_configuracion)
    };
    return (
        <Grid container alignItems="center" item xs mt={10} ml={5} mr={2}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                    Catálogo de configuraciones
                </Typography>
            </Grid>
            <Grid item xs={5} md={4}>
                <InputLabel>Tipo de configuración</InputLabel>
                <Select
                    fullWidth
                    value={idConfiguracion}
                    onChange={onSelectConfig}
                    label="Configuración"
                    >
                    {configuracionesArray.map((config) => (
                        <MenuItem key={config.id_configuracion} value={config.id_configuracion}>{config.nom_configuracion}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
            {idConfiguracion === 0 && 
                <PaymentComponent />
            }
            {idConfiguracion === 1 && 
                <CancelationPolicyComponent />
            }
            {idConfiguracion === 2 && 
                <PrivacyPolicyComponent />
            }
            {idConfiguracion === 3 && 
                <TermsAndConditionsComponent />
            }
            </Grid>
        </Grid>
    )
}

export default ConfigurationComponent