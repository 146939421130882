import Axios from "../Utils/AxiosModule"

class ReservationService {
    async consultarDepartamentos(numHuespedes, fecInicio, fecFin) {
        return await Axios.get(`/reserva/${numHuespedes}/${fecInicio}/${fecFin}`)
    }
    async obtenerCheckoutConekta(objetoConekta) {
        return await Axios.post(`/pago`, objetoConekta)
    }
    async guardarReservacion(requestReservacion) {
        return await Axios.post(`/reserva`, requestReservacion)
    }
}
const reservationService =  new ReservationService()
export default reservationService
