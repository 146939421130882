import React  from "react"
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

const CustomTextField = ({ value, name, label, required, multiline, type, regex, icon, inputProps, onChange }) => (
   
    <TextField
        margin="normal"
        required={required}
        fullWidth
        multiline={multiline}
        name={name}
        label={label}
        value={value}
        inputProps={inputProps}
        InputProps= {{
            startAdornment: (
              <InputAdornment position="start">
                {icon}
              </InputAdornment>
            )
          }}
        type={type}
        onKeyDown={(event) => {
            const value = event.target.value;
            if (regex !== undefined && !regex.test(value + event.key)){
                // Allow the backspace key (keyCode 8) to delete characters
                if (event.keyCode !== 8 && event.keyCode !== 46) {
                  // Prevent the default behavior if the key is not allowed
                  event.preventDefault();
                }
            }
        }}
        onChange={onChange}
    />
)
export default CustomTextField