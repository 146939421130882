import axios from 'axios'

let AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
})

AxiosInstance.interceptors.request.use(function (config) {
    let token = sessionStorage.getItem("auth");
    config.headers["Authorization"] = "Bearer " + token;
    return config;
});

AxiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        // handle error: inform user, go to login, etc
        sessionStorage.removeItem('auth')
        sessionStorage.removeItem('objetoUsuario')
        window.location.reload()
    } else {
        return Promise.reject(error);
    }
});
  
export default AxiosInstance