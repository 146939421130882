import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Chip, FormControl } from '@mui/material';

function MultiSelect({ options, value, idKey, labelKey, onChange }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions, onChange]);

  useEffect(() => {
    let arraySeleccionados = []
    options.map(val => (
      value.filter(a =>
        a[idKey] === val[idKey]? arraySeleccionados.push(val):null
      )
    ))
    setSelectedOptions(arraySeleccionados)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };
  
  return (
    <FormControl fullWidth>
      <Select
        labelId="multi-select-label"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={(selected) => (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selected.map((value, index) => (
              <Chip key={index} label={value[labelKey]} />
            ))}
          </div>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option[idKey]} value={option}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;
