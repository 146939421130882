import React, { useState, useEffect, useRef } from 'react';
import Loading from '../Components/Loading';
import { Grid, Button, Table, Typography, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CustomAlert from '../Components/CustomAlert'
import LockResetIcon from '@mui/icons-material/ToggleOn';
import UnlockResetIcon from '@mui/icons-material/ToggleOff';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import attrationService from '../Services/AttrationService';
import Constants from '../Utils/Constants';
import CustomConfirmDialog from '../Components/CustomConfirmDialog'
import AttrationsModal from './AttrationsModal';
import CustomTextField from '../Components/CustomTextField';

function AttrationComponent() {
    const messageType = Constants.MESSAGE_TYPE
    const [idGiro, setIdGiro] = useState('');
    const [girosArray, setGirosArray] = useState([])
    const [textFieldValue, setTextFieldValue] = useState({nomAttration: ""})
    const { nomAttration } = textFieldValue
    const [attrationsArray, setAttrationsArray] = useState([]);
    const [loading, setLoading] = useState(false)
    const showLoading = () => setLoading(true)
    const hideLoading = () => setLoading(false)
    const alertRef = useRef()
    const confirmRef = useRef()
    const [attrationId, setAttrationId] = useState("") 
    const [attrationObject, setAttrationObject] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmed, setConfirmed] = useState(false);
    const [isConfirmedStatus, setConfirmedStatus] = useState(false);

    const getGiros = () => {
        showLoading()
        attrationService.getGiros()
          .then((response) => {
            hideLoading()
            if (response.data.response.length) {
                setGirosArray(response.data.response)
                setIdGiro(response.data.response[0].id_giro)
            } else {
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
      }

    const showAlertMessage = (message, alertType) => {
        if (alertRef.current) {
            alertRef.current.showAlert(message, alertType)
        }
    }

    const handleOpenConfirm = async (attrationObject) => {
        const confirmData = {
            titulo: "Eliminar atracción",
            descripcion: `¿Desea eliminar el atracción?`,
            textoCancelar: Constants.CANCEL_TEXT,
            textoAceptar: Constants.ACCEPT_TEXT,
            onAgree: handleAgreeConfirm
        }
        if (confirmRef.current) {
            confirmRef.current.showConfirm(confirmData)
        }
        setAttrationId(attrationObject.id_servicio_atraccion);
    };

    const handleAgreeConfirm = () => {
        setConfirmed(true);
    };

    const handleDeleteAttration = () => {
        showLoading()
        attrationService.deleteAttration(attrationId)
          .then((response) => {
            hideLoading()
            if (response.data.success) {
                searchAttrations()
                showAlertMessage("Departamento eliminado correctamente", messageType.SUCCESS)
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
            }
            setAttrationId(null);
            setConfirmed(false);
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
    };

    const handleUpdateOpenConfirm = async (attrationObject) => {
        const confirmData = {
            titulo: "Cambiar estauts atracción",
            descripcion: `¿Desea cambiar estatus?`,
            textoCancelar: Constants.CANCEL_TEXT,
            textoAceptar: Constants.ACCEPT_TEXT,
            onAgree: handleUpdateAgreeConfirm
        }
        if (confirmRef.current) {
            confirmRef.current.showConfirm(confirmData)
        }
        const attrationId = attrationObject.id_servicio_atraccion;
        setAttrationId(attrationId);
       
    };

    const handleUpdateAgreeConfirm = () => {
        setConfirmedStatus(true);
    };

    const handleUpdateStatusAttration = () => {
        showLoading()
        attrationService.changeStatusAttration(attrationId)
          .then((response) => {
            hideLoading()
            if (response.data.success) {
                searchAttrations()
                showAlertMessage("Atracción actualizado correctamente", messageType.SUCCESS)
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
            }
            setAttrationId(null);
            setConfirmedStatus(false);
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
    };

    const searchAttrations = () => {
        showLoading()
        attrationService.getAttrations(idGiro, nomAttration)
          .then((response) => {
            hideLoading()
            if (response.data.response.length) {
                setAttrationsArray(response.data.response)
            } else {
                setAttrationsArray([])
                showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
            }
          })
          .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
          })
      }

    const handleSelectGiro = (event) => {
        setIdGiro(event.target.value);
    };

    const handleOpenModal = (attration = null) => {
        setAttrationObject(attration);
        setIsModalOpen(true);
    };

    const handleGuardarAttration = (message) => {
        searchAttrations()
        setIsModalOpen(false);
        showAlertMessage(message, messageType.SUCCESS)
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setTextFieldValue((prev) => ({
          ...prev,
          [name]: value,
        }))
      }

    useEffect(() => {
        getGiros()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (attrationId != null && isConfirmed) {
            handleDeleteAttration()
        } else if (attrationId != null && isConfirmedStatus) {
            handleUpdateStatusAttration()
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [attrationId,isConfirmed,isConfirmedStatus]);
    return (
        <Grid container alignItems="center" item spacing={2} xs mt={10} ml={5} mr={2}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Catálogo de atracciones
                </Typography>
            </Grid>
            <Grid item xs={5} md={4}>
                <InputLabel>Giros</InputLabel>
                <Select
                    fullWidth
                    value={idGiro}
                    onChange={handleSelectGiro}
                    >
                    {girosArray.map((giros) => (
                        <MenuItem key={giros.id_giro} value={giros.id_giro}>{giros.nom_giro}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={6} md={2} mt={2}>
                <CustomTextField
                    name="nomAttration"
                    label="Atracción"
                    value={nomAttration}
                    regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                    onChange={handleChange} />
            </Grid>
            <Grid item xs={1} md={2}>
                <Button startIcon={<Search />} title='Buscar Atracción' onClick={() => searchAttrations()}/>
                <Button startIcon={<AddIcon />} title='Agregar Atracción' onClick={() => handleOpenModal(null)} />
            </Grid>
            
            {/*Equivalente al show/hide segun la condicion se muestra el elemento*/
                attrationsArray.length > 0 &&
                <Grid item xs={12} mt={2}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Giro</TableCell>
                                <TableCell>URL</TableCell>
                                <TableCell>Costo</TableCell>
                                <TableCell>Estatus</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attrationsArray.map((attration) => (
                            <TableRow key={attration.id_servicio_atraccion}>
                                <TableCell>{attration.nom_atraccion}</TableCell>
                                <TableCell>{attration.nom_giro}</TableCell>
                                <TableCell>{attration.url_atraccion}</TableCell>
                                <TableCell>{attration.imp_costo}</TableCell>
                                <TableCell>{attration.nom_estatus}</TableCell>
                                <TableCell>
                                <Tooltip title="Editar Atracción">
                                    <Button color='success' onClick={() => handleOpenModal(attration)}><EditIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Cambiar estatus">
                                    <Button color='secondary' onClick={() => handleUpdateOpenConfirm(attration)}>{attration.opc_activo ?<LockResetIcon /> : <UnlockResetIcon />}</Button>
                                </Tooltip> 
                                <Tooltip title="Eliminar Atracción">
                                    <Button color='error' onClick={() => handleOpenConfirm(attration)}><DeleteIcon /></Button>
                                </Tooltip>                            
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
            <AttrationsModal attration={attrationObject} open={isModalOpen} onSave={handleGuardarAttration} onClose={() => setIsModalOpen(false)} />
            <Loading open={loading} handleClose={hideLoading} />
            <CustomAlert ref={alertRef} />
            <CustomConfirmDialog ref={confirmRef}/>
        </Grid>
    )
}
export default AttrationComponent