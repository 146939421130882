import Axios from "../Utils/AxiosModule"

class LoginService {
    async makeLogin(data) {
        return await Axios.post(`/login`, data)
    }
    async makeLogout() {
        return await Axios.post(`/logout`)
    }
}
const loginService =  new LoginService()
export default loginService
