class Functions {
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    reloadComponent() {
        window.location.reload()
    }
}

const functions =  new Functions()
export default functions