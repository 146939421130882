import React, { useRef } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AccountCircle } from '@mui/icons-material'
import KeyIcon from '@mui/icons-material/Key'
import Constants from '../Utils/Constants'
import CustomTextField from '../Components/CustomTextField'
import Loading from '../Components/Loading'
import CustomAlert from '../Components/CustomAlert'
import LoginService from '../Services/LoginService'

const LoginComponent = () => {
  const defaultTheme = createTheme()
  const [textFieldValue, setTextFieldValue] = useState({user: "", password: ""})
  const [loading, setLoading] = useState(false)
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const alertRef = useRef()
  const { user, password } = textFieldValue
  const messageType = Constants.MESSAGE_TYPE
  const navigate = useNavigate();

  const handleChange = (e) => {
    const {name, value} = e.target
    setTextFieldValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const showAlertMessage = (message, alertType) => {
    if (alertRef.current) {
        alertRef.current.showAlert(message, alertType)
    }
}

  const makeLogin = () => {
    if (!user) {
      showAlertMessage("Usuario es requerido!", messageType.WARNING)
      return
    }
    if (!password) {
      showAlertMessage("Contraseña es requerida!", messageType.WARNING)
      return
    }
    showLoading()
    const request = {
      nom_usuario: user,
      des_contrasena: password
    }    
    LoginService.makeLogin(request)
      .then((response) => {
        hideLoading()
        if (response.data.success) {
          const opcionesAsignadas = []
          response.data.opciones_menu.forEach(opcion => {
            const optionObject = {
              id: opcion.id_opcion_menu,
              ruta: opcion.nom_ruta,
              nombre: opcion.nom_opcion_menu,
              componente: opcion.nom_componente,
              icono: opcion.nom_icono
            }
            opcionesAsignadas.push(optionObject)
          })
          const objetoUsuario = {
            idUsuario: response.data.id_usuario,
            idTipoUsuario: response.data.id_tipo_usuario,
            nomUsuario: response.data.nom_usuario,
            opcionesMenu: opcionesAsignadas
          }
          sessionStorage.setItem('auth', response.data.token)
          sessionStorage.setItem('objetoUsuario', btoa(JSON.stringify(objetoUsuario)))
          navigate('/home', {replace: true});
          showAlertMessage("Login exitoso", messageType.SUCCESS)
        } else {
          showAlertMessage("No se encontro usuario, verifica tus credenciales", messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '75vh'
          }}
        >
          <Avatar sx={{ m: 1 }} src='../../Assets/stayin_text_logo.png' style={{
            width: 200,
            height: 200,
          }}>
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar sesión
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <CustomTextField
              required
              name="user"
              label="Usuario"
              value={user}
              icon={<AccountCircle />}
              regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
              onChange={handleChange}
            />
            <CustomTextField
              required
              type="password"
              name="password"
              label="Contraseña"
              value={password}
              icon={<KeyIcon />}
              regex={Constants.NUMERIC_DASH_REGEX}
              onChange={handleChange}
            />
            <Button
              onClick={makeLogin}
              fullWidth
              variant="contained"
              style={{backgroundColor: Constants.APP_COLOR}}
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar sesión
            </Button>
            <Loading open={loading} handleClose={hideLoading} />
            <CustomAlert ref={alertRef} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default LoginComponent