import Axios from "../Utils/AxiosModule"

class GeneralService {
    async getGeneral() {
        return await Axios.get(`/generales`)
    }
    async updateGeneral(generalRequest) {
        return await Axios.put(`/generales`, generalRequest)
    }
}
const generalService =  new GeneralService()
export default generalService
