import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import Loading from '../Components/Loading'
import StateService from '../Services/StateService'
import Constants from '../Utils/Constants';
import CustomAlert from '../Components/CustomAlert'
import CustomTextField from '../Components/CustomTextField'

function StateModal({ state, open, onSave, onClose }) {
  const [stateData, setStateData] = useState({ idEstado: '',nomEstado: ''});
  const [loading, setLoading] = useState(false)
  const [isEditar, setIsEditar] = useState(false)
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const messageType = Constants.MESSAGE_TYPE
  const alertRef = useRef()

  const handleChange = (e) => {
    setStateData({ ...stateData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (!stateData.nomEstado) {
        showAlertMessage("Nombre de usuario es requerido!", messageType.WARNING)
        return
    }
    const stateRequest = {
        id_estado: stateData.idEstado,
        nom_estado: stateData.nomEstado
    }
    showLoading()
    if (isEditar) {
        StateService.actualizarEstado(stateRequest)
        .then((response) => {
            hideLoading()
            if (response.data.success) {
                onSave(response.data.message)
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            }
        })
        .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
        })
    } else {
        StateService.agregarEstado(stateRequest)
        .then((response) => {
            hideLoading()
            if (response.data.success) {
                onSave(response.data.message)
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            }
        })
        .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
        })
    }
    
    
  };

  const showAlertMessage = (message, alertType) => {
    if (alertRef.current) {
        alertRef.current.showAlert(message, alertType)
    }
  }

  useEffect(() => {
    if (open) {
        if (state) {
            const stateObject = {
                idEstado: state.id_estado,
                nomEstado: state.nom_estado
            }
            setStateData(stateObject)
            setIsEditar(true)
        } else { 
            setIsEditar(false)
            setStateData({ idEstado: '', nomEstado: '' })
        }
    }
    
  }, [open, state]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{isEditar ? 'Editar estado' : 'Agregar estado'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10}>
                <CustomTextField
                    required
                    autoFocus
                    fullWidth
                    name="nomEstado"
                    label="Nombre estado"
                    value={stateData.nomEstado}
                    regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                    onChange={handleChange}
                    />
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Guardar</Button>
      </DialogActions>
      <Loading open={loading} handleClose={hideLoading} />
      <CustomAlert ref={alertRef} />
    </Dialog>
  );
}

export default StateModal;
