import React, { useState, useEffect } from 'react';
import Loading from './Loading';
import { Grid, Typography } from '@mui/material';
import generalService from '../Services/GeneralService';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
    const [loading, setLoading] = useState(false)
    const showLoading = () => setLoading(true)
    const hideLoading = () => setLoading(false)
    const navigate = useNavigate();
    const [desPoliticas, setPoliticas] = useState('')

    const obtenerGenerales = () => {
        showLoading()
        generalService.getGeneral()
          .then((response) => {
            hideLoading()
            if (response.data.response.length) {
                setPoliticas(response.data.response[0].des_aviso_privacidad)
            } else {
                setPoliticas('')
            }
          })
          .catch((e) => {
            hideLoading()
          })
    }

    useEffect(() => {
        obtenerGenerales()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    
    return (
        <Grid container alignItems="center" mt={1}>
            <Grid item xs={4} md={2} alignSelf='center' ml={2}>
                <img
                    src='../../Assets/stayin_logo_combined.png'
                    sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                    style={{
                        width: 'auto',
                        height: 75,
                        display: 'block',
                    }}
                    onClick={() => navigate('/reserva')}
                    alt="StayIn Logo"
                />
            </Grid>
            <Grid item xs={12} ml={2}>
                <Typography variant="body2" component='div' color="text.secondary" mt={1} mb={1}>
                    {ReactHtmlParser(desPoliticas)}
                </Typography>
            </Grid>
            <Loading open={loading} handleClose={hideLoading} />
        </Grid>
    )
}
export default PrivacyPolicy