import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CustomIcon from './CustomIcon';
import Constants from '../Utils/Constants';

const ServiciosList = ({ servicios }) => {
  const [showModal, setShowModal] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  // Render first 10 items
  const renderFirstTenItems = () => {
    return servicios.slice(0, 10).map((servicio) => (
      <ListItem key={servicio.id_servicio} sx={{ width: '48%', marginBottom: '16px' }}>
        <ListItemButton>
          <ListItemIcon>
            <CustomIcon name={servicio.nom_icono} />
          </ListItemIcon>
          <ListItemText 
            primary={servicio.nom_servicio}
          />
        </ListItemButton>
      </ListItem>
    ));
  };

  // Render all items in modal
  const renderAllItemsInModal = () => {
    return (
        <Grid container direction="row" alignItems="center">
            <Dialog open={showModal} onClose={toggleModal} maxWidth="lg">
                <DialogTitle>
                <Grid container direction="row" justifyContent="flex-end">
                    <IconButton
                        aria-label="close"
                        onClick={toggleModal}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                
                </DialogTitle>
                <DialogContent>
                <List>
                    {servicios.map((servicio) => (
                    <ListItem key={servicio.id_servicio} sx={{ marginBottom: '16px' }}>
                        <ListItemButton>
                        <ListItemIcon>
                            <CustomIcon name={servicio.nom_icono} />
                        </ListItemIcon>
                        <ListItemText 
                            primary={servicio.nom_servicio}
                        />
                        </ListItemButton>
                    </ListItem>
                    ))}
                </List>
                </DialogContent>
            </Dialog> 
        </Grid>
    );
  };

  return (
    <Grid container justifyContent='center'>
      <List sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {renderFirstTenItems()}
      </List>
      {servicios.length > 10 && (
        <Button size='large' style={{backgroundColor: Constants.APP_COLOR, textTransform: 'none'}} variant='contained' onClick={toggleModal}>Mostrar todos</Button>
      )}
      {renderAllItemsInModal()}
    </Grid>
  );
};

export default ServiciosList;
