import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import Loading from '../Components/Loading'
import UserService from '../Services/UserService'
import Constants from '../Utils/Constants';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CustomAlert from '../Components/CustomAlert'
import CustomTextField from '../Components/CustomTextField'

function UserModal({ user, open, onSave, onClose }) {
  const [userData, setUserData] = useState({ idUsuario: '',nomUsuario: '', nomArrendador: '' });
  const [loading, setLoading] = useState(false)
  const [isEditar, setIsEditar] = useState(false)
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const [userTypes, setUserTypes] = useState([])
  const messageType = Constants.MESSAGE_TYPE
  const alertRef = useRef()
  const [idTipoUsuario, setIdTipoUsuario] = useState('');

  const handleSelectChange = (event) => {
    setIdTipoUsuario(event.target.value);
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (!userData.nomUsuario) {
        showAlertMessage("Nombre de usuario es requerido!", messageType.WARNING)
        return
    }
    if (!userData.nomArrendador) {
        showAlertMessage("Nombre de arrendador es requerido!", messageType.WARNING)
        return
    }
    const userRequest = {
        id_usuario: userData.idUsuario,
        nom_usuario: userData.nomUsuario,
        nom_arrendador: userData.nomArrendador,
        id_tipo_usuario: idTipoUsuario,
    }
    showLoading()
    if (isEditar) {
        UserService.actualizarUsuario(userRequest)
        .then((response) => {
            hideLoading()
            if (response.data.success) {
                onSave(response.data.message)
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            }
        })
        .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
        })
    } else {
        UserService.agregarUsuario(userRequest)
        .then((response) => {
            hideLoading()
            if (response.data.success) {
                onSave(response.data.message)
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            }
        })
        .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
        })
    }
    
    
  };

  const showAlertMessage = (message, alertType) => {
    if (alertRef.current) {
        alertRef.current.showAlert(message, alertType)
    }
  }

  const getUserTypes = () => {
    showLoading()
    UserService.consultarTiposUsuarios()
      .then((response) => {
        hideLoading()
        if (response.data.response.length) {
            setUserTypes(response.data.response)
            setIdTipoUsuario(response.data.response[0].id_tipo_usuario)
        } else {
            setUserTypes([])
            showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  }

  useEffect(() => {
    if (open) {
        if (user) {
            const userObject = {
                idUsuario: user.id_usuario,
                nomUsuario: user.nom_usuario,
                nomArrendador: user.nom_arrendador
            }
            setUserData(userObject)
            setIdTipoUsuario(user.id_tipo_usuario)
            setIsEditar(true)
        } else { 
            setIsEditar(false)
            setUserData({ idUsuario: '', nomUsuario: '', nomArrendador: '' })
        }
    }
    
  }, [open, user]);

    useEffect(() => {
        getUserTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{isEditar ? 'Editar usuario' : 'Agregar usuario'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10}>
                <CustomTextField
                    required
                    autoFocus
                    fullWidth
                    name="nomUsuario"
                    label="Nombre usuario"
                    value={userData.nomUsuario}
                    regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                    onChange={handleChange}
                    />
            </Grid>
            <Grid item xs={10}>
                <CustomTextField
                    required
                    autoFocus
                    fullWidth
                    name="nomArrendador"
                    label="Nombre arrendador"
                    value={userData.nomArrendador}
                    regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                    onChange={handleChange}
                    />
            </Grid>
            <Grid item xs={10}>
                <InputLabel>Tipo usuario</InputLabel>
                <Select
                    fullWidth
                    value={idTipoUsuario}
                    onChange={handleSelectChange}
                    label="Tipo usuario"
                    >
                    {userTypes.map((userType) => (
                        <MenuItem key={userType.id_tipo_usuario} value={userType.id_tipo_usuario}>{userType.nom_tipo_usuario}</MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Guardar</Button>
      </DialogActions>
      <Loading open={loading} handleClose={hideLoading} />
      <CustomAlert ref={alertRef} />
    </Dialog>
  );
}

export default UserModal;
