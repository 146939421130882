import Axios from "../Utils/AxiosModule"

class UserService {
    async consultarUsuarios(nomUsuario) {
        return await Axios.get(`/usuarios/${nomUsuario}`)
    }
    async agregarUsuario(requestUsuario) {
        return await Axios.post(`/usuarios`, requestUsuario)
    }
    async actualizarUsuario(requestUsuario) {
        return await Axios.put(`/usuarios`, requestUsuario)
    }
    async actualizarContrasena(idUsuario) {
        return await Axios.put(`/usuarios/contrasena/${idUsuario}`)
    }
    async eliminarUsuario(idUsuario) {
        return await Axios.delete(`/usuarios/${idUsuario}`)
    }
    async consultarTiposUsuarios() {
        return await Axios.get(`/tiposusuarios`)
    }
}
const userService =  new UserService()
export default userService
