import React, { useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "dayjs/locale/es";
import "dayjs/locale/en";

function CustomDatePicker({ label, initialValue, onChange, minDate, maxDate, slots }) {
  const [selectedDate, setSelectedDate] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label={label}
          minDate={minDate}
          maxDate={maxDate}
          value={selectedDate}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          slotProps={{
            textField: {
              onClick: () => setIsOpen(true)
            }
          }}
          slots={slots}
          onChange={handleDateChange}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
