import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Constants from '../Utils/Constants'
import CustomIcon from '../Components/CustomIcon'
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography'
import loginService from '../Services/LoginService'
import Loading from '../Components/Loading'
import { useRef, useState } from 'react'

const drawerWidth = 240

function ResponsiveDrawer(props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)
  const objetoUsuario = JSON.parse(atob(sessionStorage.getItem('objetoUsuario')))
  const opcionesUsuario = objetoUsuario.opcionesMenu
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const messageType = Constants.MESSAGE_TYPE
  const alertRef = useRef()

  const handleDrawerClose = () => {
    setIsClosing(true)
    setMobileOpen(false)
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false)
  }
  
  const logoutUser = () => {
    showLoading()
    loginService.makeLogout()
      .then((response) => {
        hideLoading()
        if (response.data.success) {
          sessionStorage.removeItem('auth')
          sessionStorage.removeItem('objetoUsuario')
          navigate('/login')
        } else {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  }

  const showAlertMessage = (message, alertType) => {
    if (alertRef.current) {
        alertRef.current.showAlert(message, alertType)
    }
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen)
    }
  }

  const drawer = (
    <div>
        <Toolbar style={{ background: Constants.APP_COLOR }}/>
        <Divider />
        <List>
          {opcionesUsuario.map((route, index) => (
            <ListItem key={route.id}>
              <ListItemButton onClick={()=> navigate(route.ruta)}>
                <ListItemIcon>
                <CustomIcon name={route.icono} />
                </ListItemIcon>
                <ListItemText primary={route.nombre} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem>
              <ListItemButton onClick={()=> logoutUser()}>
                <ListItemIcon>
                <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={Constants.LOGOUT} />
              </ListItemButton>
            </ListItem>
        </List>
    </div>
  )

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ background: Constants.APP_COLOR }}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {"Bienvenido: "+ objetoUsuario.nomUsuario + "!"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Loading open={loading} handleClose={hideLoading} />
    </Box>
  )
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
}

export default ResponsiveDrawer