// GenericAlert.js
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Snackbar from '@mui/material/Snackbar'

const CustomAlert = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('info')

    // Function to show the alert with a specific message
    useImperativeHandle(ref, () => ({
        showAlert(message, alertType) {
            setAlertMessage(message)
            setAlertType(alertType)
            setOpen(true)
        }
    }))
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return
        }
    
        setOpen(false)
    }
    return (
        <Snackbar 
            open={open} 
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    severity={alertType}
                    variant="filled"
                    sx={{ width: '100%' }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {alertMessage}
                </Alert>
        </Snackbar>
    )
})

export default CustomAlert