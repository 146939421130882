import React, { useState, useRef, useEffect } from 'react';
import { Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StateModal from './StateModal';
import { Search } from '@mui/icons-material';
import CustomTextField from '../Components/CustomTextField';
import Constants from '../Utils/Constants';
import StateService from '../Services/StateService'
import Loading from '../Components/Loading'
import CustomAlert from '../Components/CustomAlert'
import CustomConfirmDialog from '../Components/CustomConfirmDialog'

function StateComponent() {
  const [states, setStates] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState({nomState: ""})
  const { nomState } = textFieldValue
  const [currentEstado, setCurrentState] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const messageType = Constants.MESSAGE_TYPE
  const alertRef = useRef()
  const confirmRef = useRef()
  const [stateId, setStateId] = useState(null)
  const [opcAccion, setOpcAccion] = useState(null);

  const handleOpenConfirm = (stateObject) => {
    const confirmData = {
        titulo: "Eliminar estado",
        descripcion: `¿Desea eliminar el estado ${stateObject.nom_estado} ?`,
        textoCancelar: Constants.CANCEL_TEXT,
        textoAceptar: Constants.ACCEPT_TEXT,
        onAgree: handleAgreeConfirm
    }
    if (confirmRef.current) {
        confirmRef.current.showConfirm(confirmData)
    }
    setStateId(stateObject.id_estado)
  };

  const handleAgreeConfirm = () => {
    setOpcAccion(1)
  };

  const handleOpenModal = (state = null) => {
    setCurrentState(state);
    setIsModalOpen(true);
  };
  
  const handleDeleteState = () => {
    showLoading()
    StateService.eliminarEstado(stateId)
      .then((response) => {
        hideLoading()
        if (response.data.success) {
            searchState()
            showAlertMessage("Estado eliminado correctamente", messageType.SUCCESS)
        } else {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
        }
        setStateId(null)
        setOpcAccion(null)
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  };

  const searchState = () => {
    showLoading()
    StateService.consultarEstado(nomState)
      .then((response) => {
        hideLoading()
        if (response.data.response.length) {
          setStates(response.data.response)
        } else {
            setStates([])
            showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  }

  const showAlertMessage = (message, alertType) => {
    if (alertRef.current) {
        alertRef.current.showAlert(message, alertType)
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setTextFieldValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  useEffect(() => {
    if(stateId != null && opcAccion === 1){
      handleDeleteState()                 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [stateId,opcAccion]);

  const handleSaveState = (message) => {
    searchState()
    setIsModalOpen(false);
    if (currentEstado != null) {
        showAlertMessage(message, messageType.SUCCESS)
    } else {
        const confirmData = {
            titulo: "Estado agregado",
            descripcion: message,
            textoCancelar: null,
            textoAceptar: Constants.ACCEPT_TEXT,
        }
        if (confirmRef.current) {
            confirmRef.current.showConfirm(confirmData)
        }
    }
  };

  return (
    <Grid container alignItems="center" item spacing={3} xs mt={10} ml={5} mr={2}>
       <Grid item xs={6}>
          <CustomTextField
            name="nomState"
            label="Estado"
            value={nomState}
            regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
            onChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <Button startIcon={<Search />} onClick={() => searchState()} title='Buscar tipo de pago'/>
        <Button startIcon={<AddIcon />} onClick={() => handleOpenModal(null)} title='Agregar tipo de pago'/>
      </Grid>
      {/*Equivalente al show/hide segun la condicion se muestra el elemento*/
        states.length > 0 &&
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Acciones</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {states.map((state) => (
                <TableRow key={state.id_estado}>
                    <TableCell>{state.nom_estado}</TableCell>
                    <TableCell>
                    <Tooltip title="Eliminar estado">
                        <Button onClick={() => handleOpenConfirm(state)}><DeleteIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Editar estado">
                        <Button onClick={() => handleOpenModal(state)}><EditIcon /></Button>
                    </Tooltip>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
      }
      <StateModal state={currentEstado} open={isModalOpen} onSave={handleSaveState} onClose={() => setIsModalOpen(false)} />
      <Loading open={loading} handleClose={hideLoading} />
      <CustomAlert ref={alertRef} />
      <CustomConfirmDialog ref={confirmRef}/>
    </Grid>
  );
}

export default StateComponent;