import { Grid } from '@mui/material';
import React, { useEffect, useRef } from 'react';

const CheckoutConekta = ({idCheckout, onFinalizePayment}) => {
    const isListenerRegistered = useRef(false);

    useEffect(() => {
      const script = document.createElement('script');
      if (idCheckout) {         
        script.src = process.env.REACT_APP_CONEKTA_API_URL;
        script.async = true;
        document.body.appendChild(script);
    
        script.onload = () => {
          if (!window.ConektaCheckoutComponents || isListenerRegistered.current) return;
    
          window.ConektaCheckoutComponents.Integration({
            targetIFrame: "#conektaIframeContainer",
            checkoutRequestId: idCheckout,
            publicKey: process.env.REACT_APP_CONEKTA_API_KEY,
            options: {},
            styles: {},
            onFinalizePayment: function(event){
              onFinalizePayment(event)
            }
          });
    
          isListenerRegistered.current = true;
        };
      }
      return () => {
        document.body.removeChild(script);
      };
    }, [idCheckout, onFinalizePayment]);
  
    
  
    return (
      <Grid container >
        <Grid item xs={12}>
          <div id="conektaIframeContainer" style={{height: '600px'}}></div>
        </Grid>
      </Grid>
    );
  };
  
  export default CheckoutConekta;