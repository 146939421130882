// IconComponent.jsx
import React from 'react';
// Import specific icons from MUI
import {
  People,
  Apartment,
  HotTub,
  LocalLaundryService,
  Flatware,
  Pool,
  FireExtinguisher,
  AcUnit,
  Iron,
  Checkroom,
  Soap,
  Bathtub,
  Tv,
  Wifi,
  LocationCity,
  Payments,
  Attractions,
  EditNote,
  Settings
} from '@mui/icons-material';

// Import more icons as needed

// Mapping of icon names to MUI icon components
const iconMap = {
    People: People,
    Apartment: Apartment,
    HotTub: HotTub,
    LocalLaundryService: LocalLaundryService,
    Flatware: Flatware,
    Pool: Pool,
    FireExtinguisher: FireExtinguisher,
    AcUnit: AcUnit,
    Iron: Iron,
    Checkroom: Checkroom,
    Soap: Soap,
    Bathtub: Bathtub,
    Tv: Tv,
    Wifi: Wifi,
    State: LocationCity, 
    Payment: Payments,
    Attraction: Attractions,
    AppRegistration: EditNote,
    Settings: Settings
  // add more icons to this map as needed
};

const CustomIcon = ({ name, ...props }) => {
  // Dynamically select the icon component
  const Icon = iconMap[name];

  if (!Icon) {
    // Return null or a default icon if the icon name does not exist
    return null;
  }

  // Return the icon component
  return <Icon {...props} />;
};

export default CustomIcon;