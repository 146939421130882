import Axios from "../Utils/AxiosModule"

class DepartamentoService {
    async consultarDepartamento(idCiudad, idUsuario) {
        return await Axios.get(`/departamento/${idCiudad}/${idUsuario}`)
    }
    async consultarCiudades() {
        return await Axios.get(`/ciudad`)
    }
    async consultarServicios() {
        return await Axios.get(`/servicio`)
    }
    async agregarDepartamento(deptoRequest) {
        return await Axios.post(`/departamento`, deptoRequest)
    }
    async actualizarDepartamento(deptoRequest) {
        return await Axios.put(`/departamento`, deptoRequest)
    }
    async eliminarDepartamento(idDepartamento) {
        return await Axios.delete(`/departamento/${idDepartamento}`)
    }
}
const departamentoService =  new DepartamentoService()
export default departamentoService
