import Axios from "../Utils/AxiosModule"

class PaymentService {
    headers = {
        Authorization: "Bearer " + sessionStorage.getItem("auth")
    }
    async getPayment(namPayment) {
        return await Axios.get(`/tipospagos/${namPayment}`)
    }
    async addPayment(requestPayment) {
        return await Axios.post(`/tipospagos`, requestPayment)
    }
    async updatePayment(requestPayment) {
        return await Axios.put(`/tipospagos`, requestPayment)
    }
    async deletePayment(idPayment) {
        return await Axios.delete(`/tipospagos/${idPayment}`)
    }
    async updateStatePayment(idPayment) {
        return await Axios.put(`/tipospagos/estado/${idPayment}`)
    }
    async getRunWay() {
        return await Axios.get(`/pasarelas`)
    }
}
const paymentService =  new PaymentService()
export default paymentService