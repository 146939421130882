import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, CardActionArea } from '@mui/material';

function CustomImageList({ itemData }) {
  const [open, setOpen] = useState(false);
  const [fotosArray, setFotosArray] = useState([])
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };  

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    if (itemData) {
      setFotosArray(itemData)
    }
  }, [itemData, fotosArray])
  return (
    <Grid container direction="row" justifyContent="center">
      { fotosArray.length > 0 && 
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <ImageList cols={1} rowHeight={300} style={{marginRight: 4}}>
                <CardActionArea>
                  <ImageListItem onClick={() => handleImageClick(fotosArray[0].img)}>
                    <img
                      src={fotosArray[0].img}
                      alt={fotosArray[0].title}
                      loading="lazy"
                      style={{
                        width: '100%',
                        height: '100%'
                      }}
                    />
                  </ImageListItem>
                </CardActionArea>
              </ImageList>
            </Grid>
            <Grid item xs={6}>
              <ImageList cols={2} rowHeight={150}>
                {fotosArray.map((item, index) => (
                    index >= 1 && (
                      <CardActionArea key={index}>
                        <ImageListItem key={index} onClick={() => handleImageClick(item.img)}>
                          <img
                            src={item.img}
                            alt={item.title}
                            loading="lazy"
                            style={{
                              width: '100%',
                              height: 150
                            }}
                          />
                        </ImageListItem>
                      </CardActionArea>
                    )
                  
                ))}
              </ImageList>
            </Grid>
          </Grid>
        </Grid>
      }
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
          <Grid container direction="row" justifyContent="flex-end">
              <IconButton
                aria-label="close"
                onClick={handleClose}>
                  <CloseIcon />
              </IconButton>
          </Grid>
          
        </DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img src={selectedImage} alt={selectedImage} style={{ maxWidth: '100%', height: 'auto' }} />
          )}
        </DialogContent>
      </Dialog> 
      </Grid>
  );
}

export default CustomImageList;

