import React, { useState, useRef, useEffect } from 'react';
import { Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/ToggleOn';
import PaymentModal from './PaymentModal';
import { Search } from '@mui/icons-material';
import CustomTextField from '../Components/CustomTextField';
import Constants from '../Utils/Constants';
import PaymentService from '../Services/PaymentService'
import Loading from '../Components/Loading'
import CustomAlert from '../Components/CustomAlert'
import CustomConfirmDialog from '../Components/CustomConfirmDialog'


function PaymentComponent() {
  const [payments, setPayments] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState({namPayment: ""})
  const { namPayment } = textFieldValue
  const [currentPayment, setCurrentPayment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const messageType = Constants.MESSAGE_TYPE
  const alertRef = useRef()
  const confirmRef = useRef()
  const [paymentId, setPaymentId] = useState(null);
  const [opcAccion, setOpcAccion] = useState(null);
  

  const handleOpenConfirm = (paymentObject) => {
    const confirmData = {
        titulo: "Eliminar tipo de pago",
        descripcion: `¿Desea eliminar tipo de pago ${paymentObject.nom_tipo_pago} ?`,
        textoCancelar: Constants.CANCEL_TEXT,
        textoAceptar: Constants.ACCEPT_TEXT,
        onAgree: handleAgreeConfirm
    }
    if (confirmRef.current) {
        confirmRef.current.showConfirm(confirmData)
    }
    setPaymentId(paymentObject.id_tipo_pago) 
    
  };

  useEffect(() => {
    if(paymentId != null){
        switch(opcAccion) {

            case 1:
                handleDeletePayment()
                break;
            case 2:
                handleAgreeDisableEnable()
              break;
            default:
                break;

        }           
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [paymentId,opcAccion]);


  const handleAgreeConfirm = () => {
    setOpcAccion(1)
  };

  const handleOpenModal = (payment = null) => {
    setCurrentPayment(payment);
    setIsModalOpen(true);
  };

  const handleDisableEnable = (paymentObject) => {
    
    const confirmData = {
        titulo: "Cambiar estatus",
        descripcion: `¿Desea cambiar el estatus del tipo de pago ${paymentObject.nom_tipo_pago} ?`,
        textoCancelar: Constants.CANCEL_TEXT,
        textoAceptar: Constants.ACCEPT_TEXT,
        onAgree: handleAgreeConfirmDisableEnable
    }
    if (confirmRef.current) {
        confirmRef.current.showConfirm(confirmData)
    }
    setPaymentId(paymentObject.id_tipo_pago)
  }

  const handleAgreeConfirmDisableEnable = () => {
    setOpcAccion(2)
  };
  

  
  const handleAgreeDisableEnable  = () => {
    showLoading()
    
    PaymentService.updateStatePayment(paymentId)
      .then((response) => {
        hideLoading()
        if (response.data.success) {
            const confirmData = {
                titulo: "Tipo de pago actualizado",
                descripcion: response.data.message,
                textoCancelar: null,
                textoAceptar: Constants.ACCEPT_TEXT,
            }
            if (confirmRef.current) {
                confirmRef.current.showConfirm(confirmData)
            }
            setOpcAccion(null)
            setPaymentId(null)
            searchPayment()
        } else {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  }

  const handleDeletePayment = () => {
    showLoading()
    PaymentService.deletePayment(paymentId)
      .then((response) => {
        hideLoading()
        if (response.data.success) {
            searchPayment()
            showAlertMessage("tipo de pago eliminado correctamente", messageType.SUCCESS)
        } else {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
        }
        setOpcAccion(null)
        setPaymentId(null)
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  };

  const searchPayment = () => {
    showLoading()
    PaymentService.getPayment(namPayment)
      .then((response) => {
        hideLoading()
        if (response.data.response.length) {
          setPayments(response.data.response)
        } else {
            setPayments([])
            showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  }

  const showAlertMessage = (message, alertType) => {
    if (alertRef.current) {
        alertRef.current.showAlert(message, alertType)
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setTextFieldValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSavePayment = (message) => {
    searchPayment()
    setIsModalOpen(false);
    if (currentPayment != null) {
        showAlertMessage(message, messageType.SUCCESS)
    } else {
        const confirmData = {
            titulo: "Tipo de pago agregado",
            descripcion: message,
            textoCancelar: null,
            textoAceptar: Constants.ACCEPT_TEXT,
        }
        if (confirmRef.current) {
            confirmRef.current.showConfirm(confirmData)
        }
    }
  };

  return (
    <Grid container alignItems="center" item spacing={3} xs mt={1}>
       <Grid item xs={6}>
          <CustomTextField
            name="namPayment"
            label="Tipo de pago"
            value={namPayment}
            regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
            onChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <Button startIcon={<Search />} onClick={() => searchPayment()} title='Buscar tipo de pago'/>
        <Button startIcon={<AddIcon />} onClick={() => handleOpenModal(null)} title='Agregar tipo de pago'/>
      </Grid>
      {/*Equivalente al show/hide segun la condicion se muestra el elemento*/
        payments.length > 0 &&
        <Grid item xs={12} mt={2}>
          <TableContainer component={Paper}>
              <Table aria-label="simple table">
              <TableHead>
                  <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Tipo de pago</TableCell>
                  <TableCell>Estatus</TableCell>
                  <TableCell>Acciones</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {payments.map((payment) => (
                  <TableRow key={payment.id_tipo_pago}>
                      <TableCell>{payment.nom_tipo_pago}</TableCell>
                      <TableCell>{payment.nom_pasarela}</TableCell>
                      <TableCell>{payment.nom_estatus}</TableCell>
                      <TableCell>
                      <Tooltip title="Editar">
                          <Button color='success' onClick={() => handleOpenModal(payment)}><EditIcon /></Button>
                      </Tooltip>
                      <Tooltip title="Cambiar estatus">
                          <Button color='secondary' onClick={() => handleDisableEnable(payment)}><LockResetIcon /></Button>
                      </Tooltip>
                      <Tooltip title="Eliminar">
                          <Button color='error' onClick={() => handleOpenConfirm(payment)}><DeleteIcon /></Button>
                      </Tooltip>
                      </TableCell>
                  </TableRow>
                  ))}
              </TableBody>
              </Table>
          </TableContainer>
        </Grid>
      }
      <PaymentModal payment={currentPayment} open={isModalOpen} onSave={handleSavePayment} onClose={() => setIsModalOpen(false)} />
      <Loading open={loading} handleClose={hideLoading} />
      <CustomAlert ref={alertRef} />
      <CustomConfirmDialog ref={confirmRef}/>
    </Grid>
  );
}

export default PaymentComponent;