import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import Loading from '../Components/Loading'
import PaymentService from '../Services/PaymentService'
import Constants from '../Utils/Constants';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CustomAlert from '../Components/CustomAlert'
import CustomTextField from '../Components/CustomTextField'

function PaymentModal({ payment, open, onSave, onClose }) {
  const [paymentData, setPaymentData] = useState({ idTipoPago: '',nomTipoPago: '' });
  const [loading, setLoading] = useState(false)
  const [isEditar, setIsEditar] = useState(false)
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const [paymentTypes, setPaymentTypes] = useState([])
  const messageType = Constants.MESSAGE_TYPE
  const alertRef = useRef()
  const [idPasarela, setIdTipoPasarela] = useState('');

  const handleSelectChange = (event) => {
    setIdTipoPasarela(event.target.value);
  };

  const handleChange = (e) => {
    setPaymentData({ ...paymentData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (!paymentData.nomTipoPago) {
        showAlertMessage("Nombre de tipo de pago es requerido!", messageType.WARNING)
        return
    }
    const paymentRequest = {
        id_tipo_pago: paymentData.idTipoPago,
        nom_tipo_pago: paymentData.nomTipoPago,
        id_pasarela: idPasarela,
    }
    showLoading()
    if (isEditar) {
        PaymentService.updatePayment(paymentRequest)
        .then((response) => {
            hideLoading()
            if (response.data.success) {
                onSave(response.data.message)
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            }
        })
        .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
        })
    } else {
        PaymentService.addPayment(paymentRequest)
        .then((response) => {
            hideLoading()
            if (response.data.success) {
                onSave(response.data.message)
            } else {
                showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            }
        })
        .catch((e) => {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
            hideLoading()
        })
    }
    
    
  };

  const showAlertMessage = (message, alertType) => {
    if (alertRef.current) {
        alertRef.current.showAlert(message, alertType)
    }
  }

  const getPaymentTypes = () => {
    showLoading()
    PaymentService.getRunWay()
      .then((response) => {
        hideLoading()
        if (response.data.response.length) {
            setPaymentTypes(response.data.response)
            setIdTipoPasarela(response.data.response[0].id_pasarela)
        } else {
            setPaymentTypes([])
            showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  }

  useEffect(() => {
    if (open) {
        if (payment) {
            const paymentObject = {
                idTipoPago: payment.id_tipo_pago,
                nomTipoPago: payment.nom_tipo_pago
            }
            setPaymentData(paymentObject)
            setIdTipoPasarela(payment.id_pasarela)
            setIsEditar(true)
        } else { 
            setIsEditar(false)
            setPaymentData({ idTipoPago: '', nomTipoPago: '' })
        }
    }
    
  }, [open, payment]);

    useEffect(() => {
        getPaymentTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{isEditar ? 'Editar tipo de pago' : 'Agregar tipo de pago'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10}>
                <CustomTextField
                    required
                    autoFocus
                    fullWidth
                    name="nomTipoPago"
                    label="Nombre tipo de pago"
                    value={paymentData.nomTipoPago}
                    regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
                    onChange={handleChange}
                    />
            </Grid>
            <Grid item xs={10}>
                <InputLabel>Pasarela</InputLabel>
                <Select
                    fullWidth
                    value={idPasarela}
                    onChange={handleSelectChange}
                    label="Pasarela"
                    >
                    {paymentTypes.map((paymentType) => (
                        <MenuItem key={paymentType.id_pasarela} value={paymentType.id_pasarela}>{paymentType.nom_pasarela}</MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Guardar</Button>
      </DialogActions>
      <Loading open={loading} handleClose={hideLoading} />
      <CustomAlert ref={alertRef} />
    </Dialog>
  );
}

export default PaymentModal;
