import React, { useState, useRef } from 'react';
import { Grid, Button, Table, Typography, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import UserModal from './UserModal';
import { Search } from '@mui/icons-material';
import CustomTextField from '../Components/CustomTextField';
import Constants from '../Utils/Constants';
import UserService from '../Services/UserService'
import Loading from '../Components/Loading'
import CustomAlert from '../Components/CustomAlert'
import CustomConfirmDialog from '../Components/CustomConfirmDialog'

function UserComponent() {
  const [users, setUsers] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState({nomUser: ""})
  const { nomUser } = textFieldValue
  const [currentUser, setCurrentUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const messageType = Constants.MESSAGE_TYPE
  const alertRef = useRef()
  const confirmRef = useRef()
  const [userId, setUserId] = useState("")

  const handleOpenConfirm = (userObject) => {
    const confirmData = {
        titulo: "Eliminar usuario",
        descripcion: `¿Desea eliminar al usuario ${userObject.nom_usuario} ?`,
        textoCancelar: Constants.CANCEL_TEXT,
        textoAceptar: Constants.ACCEPT_TEXT,
        onAgree: handleAgreeConfirm
    }
    if (confirmRef.current) {
        confirmRef.current.showConfirm(confirmData)
    }
    setUserId(userObject.id_usuario)
  };

  const handleAgreeConfirm = () => {
    handleDeleteUser()
  };

  const handleOpenModal = (user = null) => {
    setCurrentUser(user);
    setIsModalOpen(true);
  };

  const handleResetPass = (userObject) => {
    const confirmData = {
        titulo: "Reiniciar contraseña",
        descripcion: `¿Desea reiniciar la contraseña al usuario ${userObject.nom_usuario} ?`,
        textoCancelar: Constants.CANCEL_TEXT,
        textoAceptar: Constants.ACCEPT_TEXT,
        onAgree: handleAgreeResetPass
    }
    if (confirmRef.current) {
        confirmRef.current.showConfirm(confirmData)
    }
    setUserId(userObject.id_usuario)
  }   
  
  const handleAgreeResetPass  = () => {
    showLoading()
    UserService.actualizarContrasena(userId)
      .then((response) => {
        hideLoading()
        if (response.data.success) {
            const confirmData = {
                titulo: "Contraseña actualizada",
                descripcion: response.data.message,
                textoCancelar: null,
                textoAceptar: Constants.ACCEPT_TEXT,
            }
            if (confirmRef.current) {
                confirmRef.current.showConfirm(confirmData)
            }
        } else {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  }

  const handleDeleteUser = () => {
    showLoading()
    UserService.eliminarUsuario(userId)
      .then((response) => {
        hideLoading()
        if (response.data.success) {
            searchUser()
            showAlertMessage("Usuario eliminado correctamente", messageType.SUCCESS)
        } else {
            showAlertMessage(Constants.GENERIC_MESSAGE, messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  };

  const searchUser = () => {
    showLoading()
    UserService.consultarUsuarios(nomUser)
      .then((response) => {
        hideLoading()
        if (response.data.response.length) {
          setUsers(response.data.response)
        } else {
            setUsers([])
            showAlertMessage(Constants.NO_INFO_MESSAGE, messageType.WARNING)
        }
      })
      .catch((e) => {
        showAlertMessage(Constants.GENERIC_MESSAGE, messageType.ERROR)
        hideLoading()
      })
  }

  const showAlertMessage = (message, alertType) => {
    if (alertRef.current) {
        alertRef.current.showAlert(message, alertType)
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setTextFieldValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSaveUser = (message) => {
    searchUser()
    setIsModalOpen(false);
    if (currentUser != null) {
        showAlertMessage(message, messageType.SUCCESS)
    } else {
        const confirmData = {
            titulo: "Usuario agregado",
            descripcion: message,
            textoCancelar: null,
            textoAceptar: Constants.ACCEPT_TEXT,
        }
        if (confirmRef.current) {
            confirmRef.current.showConfirm(confirmData)
        }
    }
  };

  return (
    <Grid container alignItems="center" item spacing={3} xs mt={10} ml={5} mr={2}>
       <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
              Catálogo de usuarios
          </Typography>
       </Grid>
       <Grid item xs={6}>
          <CustomTextField
            name="nomUser"
            label="Usuario/Arrendador"
            value={nomUser}
            regex={Constants.ALPHA_NUMERIC_DASH_REGEX}
            onChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <Button startIcon={<Search />} onClick={() => searchUser()} title='Buscar usuario'/>
        <Button startIcon={<AddIcon />} onClick={() => handleOpenModal(null)} title='Agregar usuario'/>
      </Grid>
      {/*Equivalente al show/hide segun la condicion se muestra el elemento*/
        users.length > 0 &&
        <Grid item xs={12} mt={2}>
          <TableContainer component={Paper}>
              <Table aria-label="simple table">
              <TableHead>
                  <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Arrendador</TableCell>
                  <TableCell>Tipo Usuario</TableCell>
                  <TableCell>Acciones</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {users.map((user) => (
                  <TableRow key={user.id_usuario}>
                      <TableCell>{user.nom_usuario}</TableCell>
                      <TableCell>{user.nom_arrendador}</TableCell>
                      <TableCell>{user.nom_tipo_usuario}</TableCell>
                      <TableCell>
                      <Tooltip title="Editar usuario">
                          <Button color='success' onClick={() => handleOpenModal(user)}><EditIcon /></Button>
                      </Tooltip>
                      <Tooltip title="Reiniciar contraseña">
                          <Button color='secondary' onClick={() => handleResetPass(user)}><LockResetIcon /></Button>
                      </Tooltip>
                      <Tooltip title="Eliminar usuario">
                          <Button color='error' onClick={() => handleOpenConfirm(user)}><DeleteIcon /></Button>
                      </Tooltip>
                      </TableCell>
                  </TableRow>
                  ))}
              </TableBody>
              </Table>
          </TableContainer>
        </Grid>
      }
      <UserModal user={currentUser} open={isModalOpen} onSave={handleSaveUser} onClose={() => setIsModalOpen(false)} />
      <Loading open={loading} handleClose={hideLoading} />
      <CustomAlert ref={alertRef} />
      <CustomConfirmDialog ref={confirmRef}/>
    </Grid>
  );
}

export default UserComponent;