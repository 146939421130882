import Axios from "../Utils/AxiosModule"

class AttrationService {
    async getAttrations(idGiro, nomAttration) {
        return await Axios.get(`/atracciones/${idGiro}/${nomAttration}`)
    }
    async getGiros() {
        return await Axios.get(`/giros`)
    }
    async addAttration(attrationRequest) {
        return await Axios.post(`/atracciones`, attrationRequest)
    }
    async updateAttration(attrationRequest) {
        return await Axios.put(`/atracciones`, attrationRequest)
    }
    async changeStatusAttration(idAttration) {
        return await Axios.put(`/atracciones/${idAttration}`)
    }
    async deleteAttration(idAttration) {
        return await Axios.delete(`/atracciones/${idAttration}`)
    }
}
const attrationService =  new AttrationService()
export default attrationService
